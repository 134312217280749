/* Lato fonts */

@font-face {
  font-family: "Lato";
  src: url("./fonts/subset-Lato-Italic.woff2") format("woff2"),
    url("./fonts/subset-Lato-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/subset-Lato-HeavyItalic.woff2") format("woff2"),
    url("./fonts/subset-Lato-HeavyItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/subset-Lato-BoldItalic.woff2") format("woff2"),
    url("./fonts/subset-Lato-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/subset-Lato-Semibold.woff2") format("woff2"),
    url("./fonts/subset-Lato-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/subset-Lato-SemiboldItalic.woff2") format("woff2"),
    url("./fonts/subset-Lato-SemiboldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/subset-Lato-LightItalic.woff2") format("woff2"),
    url("./fonts/subset-Lato-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/subset-Lato-MediumItalic.woff2") format("woff2"),
    url("./fonts/subset-Lato-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/subset-Lato-Light.woff2") format("woff2"),
    url("./fonts/subset-Lato-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/subset-Lato-BlackItalic.woff2") format("woff2"),
    url("./fonts/subset-Lato-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/subset-Lato-Thin.woff2") format("woff2"),
    url("./fonts/subset-Lato-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/subset-Lato-Regular.woff2") format("woff2"),
    url("./fonts/subset-Lato-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato Hairline";
  src: url("./fonts/subset-Lato-Hairline.woff2") format("woff2"),
    url("./fonts/subset-Lato-Hairline.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/subset-Lato-Bold.woff2") format("woff2"),
    url("./fonts/subset-Lato-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato Hairline";
  src: url("./fonts/subset-Lato-HairlineItalic.woff2") format("woff2"),
    url("./fonts/subset-Lato-HairlineItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/subset-Lato-Medium.woff2") format("woff2"),
    url("./fonts/subset-Lato-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/subset-Lato-Black.woff2") format("woff2"),
    url("./fonts/subset-Lato-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/subset-Lato-ThinItalic.woff2") format("woff2"),
    url("./fonts/subset-Lato-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/subset-Lato-Heavy.woff2") format("woff2"),
    url("./fonts/subset-Lato-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* Giorgio Sans LCG */

@font-face {
  font-family: "Giorgio Sans LCG";
  src: url("./fonts/subset-GiorgioSansLCG-MediumItalic.woff2") format("woff2"),
    url("./fonts/subset-GiorgioSansLCG-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Giorgio Sans LCG";
  src: url("./fonts/subset-GiorgioSansLCG-BlackItalic.woff2") format("woff2"),
    url("./fonts/subset-GiorgioSansLCG-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Giorgio Sans LCG";
  src: url("./fonts/subset-GiorgioSansLCG-HeavyItalic.woff2") format("woff2"),
    url("./fonts/subset-GiorgioSansLCG-HeavyItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Giorgio Sans LCG";
  src: url("./fonts/subset-GiorgioSansLCG-LightItalic.woff2") format("woff2"),
    url("./fonts/subset-GiorgioSansLCG-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Giorgio Sans LCG";
  src: url("./fonts/subset-GiorgioSansLCG-Medium.woff2") format("woff2"),
    url("./fonts/subset-GiorgioSansLCG-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Giorgio Sans LCG";
  src: url("./fonts/subset-GiorgioSansLCG-ExtraLight.woff2") format("woff2"),
    url("./fonts/subset-GiorgioSansLCG-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Giorgio Sans LCG";
  src: url("./fonts/subset-GiorgioSansLCG-Thin.woff2") format("woff2"),
    url("./fonts/subset-GiorgioSansLCG-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Giorgio Sans LCG";
  src: url("./fonts/subset-GiorgioSansLCG-Light.woff2") format("woff2"),
    url("./fonts/subset-GiorgioSansLCG-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Giorgio Sans LCG";
  src: url("./fonts/subset-GiorgioSansLCG-BoldItalic.woff2") format("woff2"),
    url("./fonts/subset-GiorgioSansLCG-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Giorgio Sans LCG";
  src: url("./fonts/subset-GiorgioSansLCG-ExtraLightItalic.woff2") format("woff2"),
    url("./fonts/subset-GiorgioSansLCG-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Giorgio Sans LCG";
  src: url("./fonts/subset-GiorgioSansLCG-Black.woff2") format("woff2"),
    url("./fonts/subset-GiorgioSansLCG-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Giorgio Sans LCG";
  src: url("./fonts/subset-GiorgioSansLCG-Italic.woff2") format("woff2"),
    url("./fonts/subset-GiorgioSansLCG-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Giorgio Sans LCG";
  src: url("./fonts/subset-GiorgioSansLCG-Regular.woff2") format("woff2"),
    url("./fonts/subset-GiorgioSansLCG-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Giorgio Sans LCG";
  src: url("./fonts/subset-GiorgioSansLCG-Bold.woff2") format("woff2"),
    url("./fonts/subset-GiorgioSansLCG-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Giorgio Sans LCG";
  src: url("./fonts/subset-GiorgioSansLCG-ThinItalic.woff2") format("woff2"),
    url("./fonts/subset-GiorgioSansLCG-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Giorgio Sans LCG";
  src: url("./fonts/subset-GiorgioSansLCG-Heavy.woff2") format("woff2"),
    url("./fonts/subset-GiorgioSansLCG-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* Roboto mono */

@font-face {
  font-family: "Roboto Mono";
  src: url("./fonts/RobotoMono-Regular.ttf");
  font-weight: 400;
}
