@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

@keyframes wind {
  20% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-10vw, 0, 0);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-0.025rem, -0.025rem, 0);
  }

  20%,
  80% {
    transform: translate3d(0.025rem, 0.025rem, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(0.025rem, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(0, 0.025rem, 0);
  }
}

$start: 1;
$end: 6;

.progress {
  left: 12.5%;
  position: absolute;
  top: 6%;

  height: 100%;
  width: 37%;

  @for $i from $start through $end {
    .cabin-#{$i} {
      inset: 0;
      position: absolute;

      margin: auto;
      width: 7.65625vw;

      &.started {
        animation: shake 750ms infinite;
        animation-delay: calc(100ms * #{$i});
      }
    }

    .wind-#{$i} {
      display: none;

      left: 22.5%;
      position: absolute;
      top: 40%;

      height: 0.863rem;
      width: 5.75rem;

      transition: transform 2s linear;

      &.started {
        display: block;

        animation: wind 500ms ease-in infinite;
        animation-delay: calc(100ms * #{$i});
      }
    }
  }

  .wheel {
    bottom: 22%;
    position: absolute;

    width: 1.005208vw;

    &:nth-of-type(3) {
      left: 22.5%;
    }

    &:nth-of-type(4) {
      left: 76.5%;
    }
  }
}
