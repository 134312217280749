@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.mobile {
  @include col;

  flex: 1 0 auto;

  padding: 0 4.476rem;
  padding-bottom: 50rem;
  width: 100%;

  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  .body {
    @include col;
  }

  .data {
    display: grid;
    grid-column-gap: 2.878rem;
    grid-template-columns: 9.275rem 1fr;
  }

  .cups {
    @include row;

    align-items: center;
    justify-content: space-around;

    position: sticky;
    top: 0;
    z-index: 1;

    height: 14.788rem;

    background: rgba(21, 26, 43, 1);
  }

  .lines {
    @include col;

    justify-content: space-around;

    span {
      @include font-giorgio-sans-lcg;

      width: 100%;

      color: #eceaff;
      font-size: 8.635385rem;
      text-align: center;
    }
  }

  .bets {
    display: grid;
    grid-column-gap: 0.95948725rem;
    grid-row-gap: 0.479743625rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(13, 15.351796000217485rem);

    > div {
      &:nth-of-type(2) {
        background: url(~/src/widgets/markets/assets/images/mobile/cell-right-top.png)
          no-repeat;
        background-size: 100% 100%;
      }

      &:nth-of-type(25) {
        background: url(~/src/widgets/markets/assets/images/mobile/cell-left-bottom.png)
          no-repeat;
        background-size: 100% 100%;
      }

      &:nth-of-type(4),
      &:nth-of-type(6),
      &:nth-of-type(19),
      &:nth-of-type(21),
      &:nth-of-type(23) {
        background: url(~/src/widgets/markets/assets/images/mobile/cell-center-center.png)
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}

.laptop {
  @include col;

  flex: 1 0 auto;

  padding: 0 21.5rem;
  padding-bottom: 50rem;
  width: 100%;

  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  .body {
    @include col;
  }

  .data {
    display: grid;
    grid-column-gap: 2.878rem;
    grid-template-columns: 5.275rem 1fr;
  }

  .cups {
    @include row;

    align-items: center;
    justify-content: space-around;

    position: sticky;
    top: 0;
    z-index: 1;

    height: 7.788rem;

    background: rgba(21, 26, 43, 1);

    img {
      width: 13rem;

      &:last-child {
        width: 19rem;
      }
    }
  }

  .lines {
    @include col;

    justify-content: space-around;

    span {
      @include font-giorgio-sans-lcg;

      width: 100%;

      color: #eceaff;
      font-size: 6.07415rem;
      text-align: center;
    }
  }

  .bets {
    display: grid;
    grid-column-gap: 0.675rem;
    grid-row-gap: 0.3225rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(13, 11.2905rem);

    > div {
      &:nth-of-type(2) {
        background: url(~/src/widgets/markets/assets/images/mobile/cell-right-top.png)
          no-repeat;
        background-size: 100% 100%;
      }

      &:nth-of-type(25) {
        background: url(~/src/widgets/markets/assets/images/mobile/cell-left-bottom.png)
          no-repeat;
        background-size: 100% 100%;
      }

      &:nth-of-type(4),
      &:nth-of-type(6),
      &:nth-of-type(19),
      &:nth-of-type(21),
      &:nth-of-type(23) {
        background: url(~/src/widgets/markets/assets/images/mobile/cell-center-center.png)
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
