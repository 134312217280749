@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  @include col;

  align-items: center;
  flex: 1;
  gap: 1.5rem;

  padding: 6rem 8rem;
  width: 100%;

  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: initial;
  }

  .title {
    @include font-giorgio-sans-lcg;

    color: #e5e4e7;
    font-size: 1.75rem;
    letter-spacing: 0.01rem;
    text-align: center;
  }

  .description {
    @include font-lato;

    color: #e5e4e7;
    font-size: 1rem;
    text-align: center;
  }

  .button {
    height: 4.563rem;
    width: 15.75rem;

    background: url(~/src/assets/icons/tutorial-button.svg);
    background-size: 100% 100%;

    &:active {
      filter: brightness(0.7);
    }
  }
}

.mobile {
  @include col;

  align-items: center;
  flex: 1;
  gap: 1.5rem;

  padding: 6rem 4rem;
  width: 100%;

  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: initial;
  }

  .title {
    @include font-giorgio-sans-lcg;

    color: #e5e4e7;
    font-size: 8rem;
    letter-spacing: 0.01rem;
  }

  .description {
    @include font-lato;

    color: #e5e4e7;
    font-size: 5.117rem;
    text-align: center;
  }

  .button {
    height: 17.91042rem;
    width: 67.4839365rem;

    background: url(~/src/assets/icons/tutorial-button.svg);
    background-size: 100% 100%;

    &:active {
      filter: brightness(0.7);
    }
  }
}

.laptop {
  @include col;

  align-items: center;
  flex: 1;
  gap: 1.5rem;

  padding: 6rem 4rem;
  width: 100%;

  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: initial;
  }

  .title {
    @include font-giorgio-sans-lcg;

    color: #e5e4e7;
    font-size: 5rem;
    letter-spacing: 0.01rem;
  }

  .description {
    @include font-lato;

    color: #e5e4e7;
    font-size: 3.117rem;
    text-align: center;
  }

  .button {
    height: 13.91042rem;
    width: 63.4839365rem;

    background: url(~/src/assets/icons/tutorial-button.svg);
    background-size: 100% 100%;

    &:active {
      filter: brightness(0.7);
    }
  }
}
