.content {
  position: relative;

  height: 74%;
  width: 100%;

  .animated {
    position: absolute;
    top: 0;

    height: 100%;
    width: 100%;
  }
}
