@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  @include font-giorgio-sans-lcg;

  color: #ffffff;
  font-size: 5.375rem;
  line-height: 5.375rem;
}

.mobile,
.laptop {
  @include font-giorgio-sans-lcg;

  color: #ffffff;
  font-size: 16rem;
  line-height: 12rem;
}

.v3 {
  @include row;
  @include font-giorgio-sans-lcg;

  align-items: center;
  gap: 1.5rem;
  justify-content: center;

  color: #ffffff;
  font-size: 4.5rem;
  letter-spacing: 0.1rem;
  line-height: 5.5rem;
  word-spacing: 0.3rem;
}
