@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  @include col;

  position: relative;

  padding: 0 1.063rem;
  height: 100%;
  width: 29.125rem;

  background: rgba(21, 26, 47, 1);
  box-shadow: 0.063rem 0.063rem 0 0 rgba(55, 65, 92, 1) inset;
}

.mobile {
  @include col;

  bottom: 0;
  position: absolute;

  padding: 0 2.877rem;
  height: 100%;
  max-height: 100%;
  min-height: 16.346rem;
  width: 100%;

  background: rgba(21, 26, 47, 1);
  pointer-events: auto;
  transition: height 100ms linear;

  will-change: height;
}

.laptop {
  @include col;

  bottom: 0;
  position: absolute;

  height: 100%;
  max-height: 100%;
  min-height: 11.129015rem;
  width: 100%;

  background: rgba(21, 26, 47, 1);
  pointer-events: auto;
  transition: height 100ms linear;

  will-change: height;
}
