@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop,
.mobile,
.laptop {
  display: none;
}

.v3 {
  @include row;

  button {
    @include font-giorgio-sans-lcg;

    position: relative;

    height: 4.8125rem;
    width: 16.1875rem;

    color: #ffffff;
    font-size: 2.375rem;

    &:hover {
      &::before {
        background: #312ba0;
      }
    }

    &:active {
      &::before {
        background: #151343;
      }
    }

    &::before {
      inset: 0;
      position: absolute;
      z-index: -1;

      width: 100%;
      height: 100%;

      background: #1e1c5b;
      border-radius: 0.5rem;
      content: "";
      transform: skew(-5deg);
      transition: background 100ms linear;
    }
  }
}
