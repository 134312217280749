@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  display: none;
}

.mobile {
  @include row;

  align-items: flex-end;
  flex: 1 0 50%;
  justify-content: space-between;

  height: 9.915rem;

  .position {
    position: absolute;

    > div {
      height: 100%;
      width: 100%;

      font-size: 7rem;
    }

    &:nth-of-type(4),
    &:nth-of-type(5),
    &:nth-of-type(6) {
      > div {
        font-size: 4rem;
      }
    }
  }
}

.laptop {
  @include row;

  align-items: flex-end;
  flex: 1 0 50%;
  justify-content: space-between;

  height: 7rem;

  .position {
    position: absolute;

    > div {
      height: 100%;
      width: 100%;

      font-size: 5.25rem;
      line-height: 5rem;
    }

    &:nth-of-type(4),
    &:nth-of-type(5),
    &:nth-of-type(6) {
      > div {
        font-size: 3rem;
      }
    }
  }
}

.v3 {
  @include row;

  align-items: flex-end;
  justify-content: space-between;

  height: 3.25rem;
  width: 18.4375rem;

  .position {
    position: absolute;

    > div {
      height: 100%;
      width: 100%;

      font-size: 2.375rem;
    }

    &:nth-of-type(4),
    &:nth-of-type(5),
    &:nth-of-type(6) {
      > div {
        font-size: 1.375rem;
      }
    }
  }
}
