@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

@keyframes time {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@mixin active {
  &:active {
    filter: brightness(0.5);
  }
}

.mobile,
.laptop {
  left: 0;
  position: absolute;
  top: 0;
  z-index: 9999;

  height: 100%;
  width: 100%;

  background: #171d2b;
}

.mobile,
.laptop {
  @for $i from 1 through 6 {
    .slide-#{$i} {
      height: 100%;

      background: url(~/src/widgets/tutorial/assets/images/mobile/tutorial-#{$i}.png)
        no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}

.tap {
  position: absolute;
  z-index: 5;

  height: 100%;
  width: 100%;
}

.timelapse {
  @include row;

  align-items: center;
  gap: 1.27931633335rem;
  justify-content: center;

  position: absolute;
  top: 0;
  z-index: 5;

  height: 5.437094rem;
  padding: 0 1.9189745rem;
  width: 100%;
}

.mobile,
.laptop {
  @for $i from 1 through 6 {
    .timelapse-#{$i} {
      flex: 1 0 auto;

      position: relative;

      height: 0.95948725rem;

      background: #1d2541;
      border-radius: 2rem;

      &:before {
        position: absolute;

        height: 100%;
        width: 0%;

        background: #eceaff;
        border-radius: 2rem;
        content: "";
      }
    }

    .active {
      &:before {
        animation: time 4s linear;
        animation-fill-mode: forwards;
      }
    }

    .play {
      &:before {
        animation-play-state: running;
      }
    }

    .pause {
      &:before {
        animation-play-state: paused;
      }
    }

    .done {
      &::before {
        width: 100%;
      }
    }
  }
}

.close {
  @include active;

  position: absolute;
  right: 0.1rem;
  top: 4rem;
  z-index: 5;

  height: 15.3517rem;
  width: 15.3517rem;

  background: url(~/src/widgets/tutorial/assets/images/common/close.png) no-repeat;
  background-size: 100% 100%;
}
