@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.title {
  @include row;
  @include font-giorgio-sans-lcg;

  align-items: flex-end;
  justify-content: flex-end;
}

.desktop,
.mobile,
.laptop,
.v3 {
  @include row;
  @include font-giorgio-sans-lcg;

  align-items: flex-end;
  justify-content: flex-end;
}

.desktop {
  gap: 0.375rem;

  position: relative;

  height: 105%;

  > span {
    letter-spacing: 0.031rem;
  }

  .label {
    align-self: flex-end;

    margin-right: 2.813rem;

    font-size: 1.75rem;
    line-height: 1.8rem;
  }

  .slash {
    font-size: 5.5rem;
    line-height: 5.6rem;
  }

  .total {
    font-size: 6.375rem;
    line-height: 5.25rem;
  }

  .lap {
    position: absolute;
    right: 4.25rem;

    height: 5.25rem;
    width: 2.813rem;

    overflow: hidden;
    text-align: center;

    > span {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;

      margin: auto;

      font-size: 6.375rem;
      line-height: 5.25rem;
    }
  }

  .title {
    > span {
      font-size: 6.375rem;
      letter-spacing: 0.063rem;
      line-height: 5.25rem;
    }
  }
}

.mobile {
  gap: 0.375rem;

  position: relative;

  padding-left: 7.5rem;

  > span {
    letter-spacing: 0.01rem;
  }

  .label {
    align-self: flex-end;

    position: absolute;
    right: -7.5rem;

    font-size: 4.75rem;
    line-height: 14.5rem;
  }

  .slash {
    align-self: flex-start;

    font-size: 10.5rem;
    line-height: 19rem;
  }

  .total {
    font-size: 12.375rem;
    line-height: 20rem;
  }

  .lap {
    position: absolute;
    right: 7rem;

    height: 20rem;
    width: 6rem;

    overflow: hidden;
    text-align: center;

    > span {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;

      margin: auto;

      font-size: 12.375rem;
      line-height: 20rem;
    }
  }

  .title {
    gap: 1rem;

    padding-left: 2.5rem;

    > span {
      width: 100%;

      font-size: 12rem;
      letter-spacing: 0.05rem;
      line-height: 20rem;
    }
  }
}

.laptop {
  gap: 0.375rem;

  position: relative;

  padding-left: 4.8rem;

  > span {
    letter-spacing: 0.01rem;
  }

  .label {
    align-self: flex-end;

    position: absolute;
    right: -5.5rem;

    font-size: 2.7rem;
    letter-spacing: 0.2rem;
    line-height: 6.8rem;
  }

  .slash {
    align-self: flex-start;

    font-size: 7.4275rem;
    line-height: 10.05rem;
  }

  .total {
    font-size: 8.55rem;
    letter-spacing: 0.05rem;
    line-height: 10.55rem;
  }

  .lap {
    position: absolute;
    right: 4.3rem;

    height: 10.5rem;
    width: 6rem;

    overflow: hidden;
    text-align: center;

    > span {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;

      margin: auto;

      font-size: 8.55rem;
      letter-spacing: 0.05rem;
      line-height: 10.55rem;
    }
  }

  .title {
    gap: 1rem;

    padding-left: 2.5rem;

    > span {
      width: 100%;

      font-size: 8.55rem;
      letter-spacing: 0.05rem;
      line-height: 10.55rem;
    }
  }
}

.v3 {
  gap: 0.375rem;

  position: relative;

  padding-left: 1.5rem;

  .label {
    align-self: flex-end;

    position: absolute;
    right: -2.5rem;

    font-size: 1.5rem;
    line-height: 4.5rem;
  }

  .slash {
    font-size: 3.4rem;
    line-height: 6.2rem;
  }

  .total {
    font-size: 4.0625rem;
    line-height: 6.0625rem;
  }

  .lap {
    height: 6rem;
    width: 2rem;

    position: relative;

    overflow: hidden;
    text-align: right;

    > span {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;

      margin: auto;

      font-size: 4.0625rem;
      line-height: 6.0625rem;
    }
  }

  .title {
    gap: 1rem;

    padding-left: 1rem;

    > span {
      width: 100%;

      font-size: 4.0625rem;
      letter-spacing: 0.01rem;
      line-height: 4.5625rem;
    }
  }
}

.padded {
  padding-left: 0;
}
