@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.market {
  @include col;

  .header {
    @include row;

    align-items: center;
    flex: 1 0 11.5%;
    justify-content: space-around;

    z-index: 1;

    button {
      display: flex;
    }

    img {
      height: 2rem;
    }
  }

  .body {
    @include col;

    flex: 1 0 88.5%;

    position: relative;
  }

  .bets {
    display: grid;
    grid-column-gap: 0.1875rem;
    grid-row-gap: 0.09375rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);

    flex: 1;

    padding-bottom: 1rem;
  }

  .limit {
    bottom: 0rem;
    position: absolute;

    height: 1.1875rem;
    width: 15.75rem;

    transition: filter 300ms linear;

    &.filter {
      filter: brightness(0.65);
    }

    &:before {
      bottom: 0;
      left: 0;
      margin: auto;
      position: absolute;
      top: 0;

      height: 0.0625rem;
      width: 6rem;

      background: #435189;
      content: "";
    }

    &:after {
      bottom: 0;
      right: 0;
      margin: auto;
      position: absolute;
      top: 0;

      height: 0.0625rem;
      width: 6rem;

      background: #435189;
      content: "";
    }
  }

  .top2,
  .top3 {
    filter: brightness(0.65);

    &.light {
      filter: brightness(1);
    }
  }
}

.market.dark {
  .limit {
    bottom: 0.1rem;
    position: absolute;

    height: 1.1875rem;
    width: 15.75rem;

    background: url(~/src/widgets/markets/assets/images/v3/dark/limit-top-bottom.png);
    background-size: 100% 100%;

    &:before {
      display: none;
    }

    &:after {
      display: none;
    }
  }
}
