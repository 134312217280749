@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.mobile {
  flex: 0 0 auto;

  position: relative;

  height: 18.554rem;
  width: 100%;

  overflow: hidden;
  transition: height 100ms linear;

  &.open {
    height: 67.5rem;
  }

  .content {
    position: relative;
    z-index: 1;

    width: 100%;

    background: rgba(19, 26, 43, 0.8);
    transition: height 300ms ease-out;
  }
}

.laptop {
  flex: 0 0 auto;

  position: relative;

  height: 12.9rem;
  width: 100%;

  overflow: hidden;
  transition: height 100ms linear;

  &.open {
    height: 67.5rem;
  }

  .content {
    position: relative;
    z-index: 1;

    width: 100%;

    background: rgba(19, 26, 43, 0.8);
    transition: height 300ms ease-out;
  }
}
