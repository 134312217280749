@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.market {
  @include col;

  flex: 1;

  position: absolute;
  top: calc(0.14637 * (100vh - 17.083rem));

  padding-top: 0.701rem;
  height: calc(0.7797 * (100vh - 17.083rem));
  width: 95.5%;

  .cups {
    position: relative;

    height: 100%;
    width: 100%;

    &:before {
      position: absolute;
      top: calc(0.008 * (100vh - 17.083rem));
      left: 0.6rem;

      height: 3.749rem;
      width: 4.933rem;

      background: url(~/src/assets/icons/places.svg) no-repeat;
      background-size: contain;
      content: "";
    }
  }

  .body {
    display: grid;
    flex: 1;
    grid-column-gap: 1.088rem;
    grid-row-gap: 4.1%;
    grid-template-columns: 3.011rem 1fr;
    grid-template-rows: 8% 1fr;
  }

  .lines {
    align-items: center;
    gap: 0.25rem;
    justify-content: space-around;

    &:nth-child(2) {
      @include row;
    }

    &:nth-child(3) {
      @include col;
    }
  }

  .bets {
    display: grid;
    grid-column-gap: 0.25rem;
    grid-row-gap: 0.25rem;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }
}
