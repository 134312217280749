@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  height: 100%;
  width: 100%;
}

.mobile,
.laptop {
  inset: 0;
  position: absolute;

  height: 67.5rem;
  width: 100%;
}

.v3 {
  height: 33.75rem;
  width: 60rem;
}
