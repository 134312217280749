@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  @include row;

  position: relative;

  width: 17.5rem;

  .button {
    position: absolute;
    top: 0.05rem;

    height: 4.8125rem;
    width: 5.375rem;
  }

  .button.dec {
    left: -0.15rem;

    background: url(~/src/components/step/assets/images/desktop/button-dec-default.png)
      no-repeat;
    background-size: 100% 100%;

    &:hover {
      background: url(~/src/components/step/assets/images/desktop/button-dec-hover.png)
        no-repeat;
      background-size: 100% 100%;
    }
    &:active {
      background: url(~/src/components/step/assets/images/desktop/button-dec-active.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }

  .button.inc {
    right: -0.15rem;

    background: url(~/src/components/step/assets/images/desktop/button-inc-default.png)
      no-repeat;
    background-size: 100% 100%;

    &:hover {
      background: url(~/src/components/step/assets/images/desktop/button-inc-hover.png)
        no-repeat;
      background-size: 100% 100%;
    }
    &:active {
      background: url(~/src/components/step/assets/images/desktop/button-inc-active.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }

  .value {
    @include font-giorgio-sans-lcg;

    flex: 1;

    height: 4.8125rem;

    background: url(~/src/components/step/assets/images/desktop/value-default.png)
      no-repeat;
    background-size: 100% 100%;
    color: #a0a7b7;
    font-size: 2.625rem;
    letter-spacing: 0.01rem;
    line-height: 3.063rem;

    &:hover {
      background: url(~/src/components/step/assets/images/desktop/value-hover.png)
        no-repeat;
      background-size: 100% 100%;
    }
    &:active {
      background: url(~/src/components/step/assets/images/desktop/value-active.png)
        no-repeat;
      background-size: 100% 100%;
      color: #7cfa4c;
    }
  }

  .value.selected {
    background: url(~/src/components/step/assets/images/desktop/value-selected.png)
      no-repeat;
    background-size: 100% 100%;
    color: #7cfa4c;
  }
}

.mobile {
  @include row;

  justify-content: space-between;

  position: relative;

  height: 17.2707705rem;
  width: 60.1278676675rem;

  .button {
    position: absolute;
    top: 3.5%;
    z-index: 5;

    height: 16.4775rem;
    width: 18.402965rem;
  }

  .button.dec {
    left: 0.3rem;

    background: url(~/src/components/step/assets/images/mobile/button-dec-default.png)
      no-repeat;
    background-size: 100% 100%;

    &:active {
      background: url(~/src/components/step/assets/images/mobile/button-dec-active.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }

  .button.inc {
    right: 0.3rem;

    background: url(~/src/components/step/assets/images/mobile/button-inc-default.png)
      no-repeat;
    background-size: 100% 100%;

    &:active {
      background: url(~/src/components/step/assets/images/mobile/button-inc-active.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }

  .value {
    @include font-giorgio-sans-lcg;

    height: 17.09805rem;
    width: 59.60334rem;

    background: url(~/src/components/step/assets/images/mobile/value-default.png)
      no-repeat;
    background-size: 100% 100%;
    color: #7cfa4c;
    font-size: 8.47865rem;
    letter-spacing: 0.01rem;
    line-height: 3.063rem;
  }
}

.laptop {
  @include row;

  justify-content: space-between;

  position: relative;

  height: 13.725rem;
  width: 48.89185rem;

  .button {
    position: absolute;
    top: 2%;
    z-index: 5;

    height: 13.15rem;
    width: 14.635rem;
  }

  .button.dec {
    left: 0rem;

    background: url(~/src/components/step/assets/images/mobile/button-dec-default.png)
      no-repeat;
    background-size: 100% 100%;

    &:active {
      background: url(~/src/components/step/assets/images/mobile/button-dec-active.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }

  .button.inc {
    right: -0.6rem;

    background: url(~/src/components/step/assets/images/mobile/button-inc-default.png)
      no-repeat;
    background-size: 100% 100%;

    &:active {
      background: url(~/src/components/step/assets/images/mobile/button-inc-active.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }

  .value {
    @include font-giorgio-sans-lcg;

    height: 13.59rem;
    width: 68.875rem;

    background: url(~/src/components/step/assets/images/mobile/value-default.png)
      no-repeat;
    background-size: 100% 100%;
    color: #7cfa4c;
    font-size: 6.7435rem;
    letter-spacing: 0.01rem;
    line-height: 3.063rem;
  }
}

.v3 {
  @include row;

  bottom: 3.6rem;
  position: absolute;
  right: 36rem;
  z-index: 2;

  width: 13.125rem;

  .button {
    top: 0.09rem;
    position: absolute;

    height: 3.639375rem;
    width: 4.064375rem;
  }

  .button.dec.selected {
    background: url(~/src/components/step/assets/images/v3/common/dec-selected.png)
      no-repeat;
    background-size: 100% 100%;
  }

  .button.dec {
    left: -0.05rem;

    background: url(~/src/components/step/assets/images/v3/common/dec-default.png)
      no-repeat;
    background-size: 100% 100%;

    &:hover {
      background: url(~/src/components/step/assets/images/v3/common/dec-hover.png)
        no-repeat;
      background-size: 100% 100%;
    }
    &:active {
      background: url(~/src/components/step/assets/images/v3/common/dec-active.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }

  .button.inc.selected {
    background: url(~/src/components/step/assets/images/v3/common/inc-selected.png)
      no-repeat;
    background-size: 100% 100%;
  }

  .button.inc {
    right: -0.01rem;

    background: url(~/src/components/step/assets/images/v3/common/inc-default.png)
      no-repeat;
    background-size: 100% 100%;

    &:hover {
      background: url(~/src/components/step/assets/images/v3/common/inc-hover.png)
        no-repeat;
      background-size: 100% 100%;
    }
    &:active {
      background: url(~/src/components/step/assets/images/v3/common/inc-active.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }

  .value {
    @include font-giorgio-sans-lcg;

    flex: 1;

    height: 3.774375rem;

    background: url(~/src/components/step/assets/images/v3/common/value-default.png)
      no-repeat;
    background-size: 100% 100%;
    color: #a0a7b7;
    font-size: 1.8725rem;
    letter-spacing: 0.01rem;
    line-height: 3.063rem;

    &:hover {
      background: url(~/src/components/step/assets/images/v3/common/value-hover.png)
        no-repeat;
      background-size: 100% 100%;
    }
    &:active {
      background: url(~/src/components/step/assets/images/v3/common/value-active.png)
        no-repeat;
      background-size: 100% 100%;
      color: #7cfa4c;
    }

    span {
      display: inline-block;

      transform: skewX(10deg);
    }
  }

  .value.selected {
    background: url(~/src/components/step/assets/images/v3/common/value-selected.png)
      no-repeat;
    background-size: 100% 100%;
    color: #7cfa4c;
  }
}

.v3.dark {
  @include row;

  bottom: 3.6rem;
  position: absolute;
  right: 36rem;
  z-index: 2;

  width: 13.125rem;

  .button {
    top: 0.09rem;
    position: absolute;

    height: 3.639375rem;
    width: 4.064375rem;
  }

  .button.dec.selected {
    background: url(~/src/components/step/assets/images/v3/dark/dec-selected.png)
      no-repeat;
    background-size: 100% 100%;
  }

  .button.dec {
    left: -0.05rem;

    background: url(~/src/components/step/assets/images/v3/dark/dec-default.png)
      no-repeat;
    background-size: 100% 100%;

    &:hover {
      background: url(~/src/components/step/assets/images/v3/dark/dec-hover.png)
        no-repeat;
      background-size: 100% 100%;
    }
    &:active {
      background: url(~/src/components/step/assets/images/v3/dark/dec-active.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }

  .button.inc.selected {
    background: url(~/src/components/step/assets/images/v3/dark/inc-selected.png)
      no-repeat;
    background-size: 100% 100%;
  }

  .button.inc {
    right: -0.01rem;

    background: url(~/src/components/step/assets/images/v3/dark/inc-default.png)
      no-repeat;
    background-size: 100% 100%;

    &:hover {
      background: url(~/src/components/step/assets/images/v3/dark/inc-hover.png)
        no-repeat;
      background-size: 100% 100%;
    }
    &:active {
      background: url(~/src/components/step/assets/images/v3/dark/inc-active.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }

  .value {
    @include font-giorgio-sans-lcg;

    flex: 1;

    height: 3.774375rem;

    background: url(~/src/components/step/assets/images/v3/dark/value-default.png)
      no-repeat;
    background-size: 100% 100%;
    color: #a0a7b7;
    font-size: 1.8725rem;
    letter-spacing: 0.01rem;
    line-height: 3.063rem;

    &:hover {
      background: url(~/src/components/step/assets/images/v3/dark/value-hover.png)
        no-repeat;
      background-size: 100% 100%;
    }
    &:active {
      background: url(~/src/components/step/assets/images/v3/dark/value-active.png)
        no-repeat;
      background-size: 100% 100%;
      color: #6f98ff;
    }

    span {
      display: inline-block;

      transform: skewX(10deg);
    }
  }

  .value.selected {
    background: url(~/src/components/step/assets/images/v3/dark/value-selected.png)
      no-repeat;
    background-size: 100% 100%;
    color: #fefdff;
  }
}
