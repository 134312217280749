* {
  box-sizing: border-box;
  font-family: Giorgio Sans LCG, Arial, Helvetica, sans-serif;
  user-select: none;
}

html,
body,
body > div {
  height: 100%;
  width: 100%;

  overflow: hidden;
  touch-action: none;
}

html {
  font-size: 0.833335vw;
}

body {
  background-color: #091020;
}

button {
  padding: 0;

  background: none;
  border: none;
  outline: none;
  transition: all 200ms ease-out;
}

img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

line,
rect,
symbol,
stop {
  overflow: visible;
}
