@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.mobile {
  @include col;

  bottom: 0;
  position: fixed;
  z-index: 2;

  width: 100%;

  transition: transform 100ms linear;

  &:before {
    bottom: -3.754rem;
    position: absolute;
    z-index: -1;

    height: 80%;
    width: 100%;

    background: linear-gradient(180deg, #151a2a 0%, #151a2b 100%);
    content: "";
  }

  &.visible {
    transform: translate3d(0, -3.754rem, 0);
  }

  .panel {
    @include row;

    align-items: flex-end;
    justify-content: space-between;

    height: 23.25rem;
    padding-bottom: 3.197rem;
    padding-left: 4.476rem;
    padding-right: 3.836rem;
    width: 100%;

    background: url(~/src/widgets/panel/assets/images/mobile/panel.png) 0% 0% / cover
      no-repeat;
  }

  .controls {
    @include row;

    position: relative;

    height: 10.93495rem;
    width: 23.4114rem;

    button {
      position: absolute;
      top: -1rem;
    }

    button:first-child {
      left: -1rem;
    }

    button:last-child {
      right: 0.05rem;
    }
  }
}

.laptop {
  @include col;

  bottom: 0;
  position: fixed;
  z-index: 2;

  width: 100%;

  transition: transform 100ms linear;

  &:before {
    bottom: -3.754rem;
    position: absolute;
    z-index: -1;

    height: 80%;
    width: 100%;

    background: linear-gradient(180deg, #151a2a 0%, #151a2b 100%);
    content: "";
  }

  &.visible {
    transform: translate3d(0, -3.754rem, 0);
  }

  .panel {
    @include row;

    align-items: flex-end;
    justify-content: space-between;

    height: 18.54835rem;
    padding-bottom: 2.4rem;
    padding-left: 8.476rem;
    padding-right: 8.836rem;
    width: 100%;

    background: url(~/src/widgets/panel/assets/images/laptop/panel.png) no-repeat;
    background-size: 100% 100%;
  }

  .controls {
    @include row;

    position: relative;

    height: 10.93495rem;
    width: 23.4114rem;

    button {
      position: absolute;
      top: 0;
    }

    button:first-child {
      left: 1.15rem;
    }

    button:last-child {
      right: 0.05rem;
    }
  }
}
