@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.content {
  height: 100%;
  width: 100%;

  touch-action: none;
}
