@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  display: none;
}

.mobile,
.laptop {
  @include col;

  gap: 0.249rem;

  bottom: 0;
  position: absolute;

  max-height: 100.145rem;
  min-height: 31.103rem;
  width: 110.98rem;

  background: #1e1c5b;
  overflow: scroll;

  .small {
    align-items: center;
    justify-content: center;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.v3 {
  display: grid;
  grid-auto-rows: 4.5625rem;
  grid-column-gap: 0.9375rem;
  grid-row-gap: 0.1875rem;
  grid-template-columns: repeat(2, 27.875rem);

  bottom: 0;
  position: absolute;

  max-height: 34rem;
  min-height: 10.1875rem;
  padding-top: 0.875rem;
  width: 56.6875rem;

  background: #1e1c5b;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}
