@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.mobile {
  @include row;

  align-items: center;
  gap: 1.535rem;
  justify-content: center;

  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: -18rem;
  z-index: -1;

  height: 8.827282700125rem;
  width: 47.5905rem;

  background: rgba(30, 60, 144);
  border-radius: 1.9189745rem;
  transform: translate3d(0, 25rem, 0);
  transition: transform 100ms ease-in-out;

  &.active {
    transform: translate3d(0, 0rem, 0);
  }

  span {
    @include font-giorgio-sans-lcg;

    color: #4b64c7;
    font-size: 5.3731285rem;

    &:first-child {
      color: #eceaff;
    }
  }
}

.laptop {
  @include row;

  align-items: center;
  gap: 1.535rem;
  justify-content: center;

  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: -18.665rem;
  z-index: -1;

  height: 8.827282700125rem;
  width: 47.5905rem;

  background: rgba(30, 60, 144);
  border-radius: 1.9189745rem;
  transform: translate3d(0, 20rem, 0);
  transition: transform 100ms ease-in-out;

  &.active {
    transform: translate3d(0, 0rem, 0);
  }

  span {
    @include font-giorgio-sans-lcg;

    color: #4b64c7;
    font-size: 5.3731285rem;

    &:first-child {
      color: #eceaff;
    }
  }
}
