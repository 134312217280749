@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.light {
  bottom: 0;
  position: absolute;
  z-index: 1;

  height: 10.46875rem;
  width: 100%;

  background: url(~/src/widgets/panel/assets/images/v3/common/light.png) no-repeat;
  background-size: 100% 100%;
}

.light.dark {
  bottom: 0;
  position: absolute;
  z-index: 1;

  height: 10.46875rem;
  width: 100%;

  background: url(~/src/widgets/panel/assets/images/v3/dark/light.png) no-repeat;
  background-size: 100% 100%;
}
