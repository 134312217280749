@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.mobile {
  @include row;

  align-items: center;

  height: 18.554rem;
  padding: 0 4.5rem;
  width: 100%;
}

.laptop {
  @include row;

  align-items: center;

  height: 12.9rem;
  padding: 0 12.35965rem;
  width: 100%;
}
