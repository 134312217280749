@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.desktop {
  @include col;

  position: relative;

  .content {
    align-items: center;
    display: flex;
    justify-content: center;

    height: 7.938rem;
    width: 37.5rem;

    background: url(~/src/assets/images/desktop/dialog.png) no-repeat;
    background-size: contain;
  }

  .label {
    @include col;
    @include font-giorgio-sans-lcg;

    justify-content: center;

    height: 8.563rem;

    animation: fade 1s ease-out 0s 1;
    color: #ffffff;
    font-size: 5.375rem;
    letter-spacing: 0.219rem;
    line-height: 4.25rem;
  }
}

.mobile,
.laptop {
  @include col;

  position: relative;

  .content {
    align-items: center;
    display: flex;
    justify-content: center;

    height: 31.103rem;
    max-height: 155.639rem;
    width: 110.98rem;

    background: #1e1c5b;
  }
}

.v3 {
  @include col;

  position: relative;

  .content {
    align-items: center;
    display: flex;
    justify-content: center;

    height: 10.1875rem;

    background: url(~/src/assets/images/desktop/dialog.png) no-repeat;
    background-size: contain;
  }
}
