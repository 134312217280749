@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  @include row;
  @include font-giorgio-sans-lcg;

  justify-content: space-between;

  height: 105%;
  width: 100%;

  > span {
    &:first-child {
      font-size: 1.75rem;
      line-height: 1.8rem;
    }

    &:last-child {
      font-size: 1.75rem;
      line-height: 1.8rem;
    }
  }
}

.mobile {
  @include col;
  @include font-giorgio-sans-lcg;

  padding-left: 3.4rem;

  > span {
    &:first-child {
      font-size: 6.185rem;
      letter-spacing: 0.2rem;
      line-height: 5.85rem;
    }

    &:last-child {
      color: #9289ee;
      font-size: 3.75rem;
      letter-spacing: 0.001rem;
    }
  }
}

.laptop {
  @include col;
  @include font-giorgio-sans-lcg;

  padding-left: 2.5rem;

  > span {
    &:first-child {
      font-size: 4.35rem;
      letter-spacing: 0.12rem;
      line-height: 4rem;
    }

    &:last-child {
      color: #9289ee;
      font-size: 2.55rem;
      letter-spacing: 0.001rem;
      line-height: 3rem;
    }
  }
}

.v3 {
  @include col;
  @include font-giorgio-sans-lcg;

  padding-left: 1rem;

  > span {
    &:first-child {
      font-size: 2.085rem;
      letter-spacing: 0.06rem;
      line-height: 1.8rem;
    }

    &:last-child {
      color: #9289ee;
      font-size: 1.25rem;
      letter-spacing: 0.01rem;
    }
  }
}
