@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop,
.mobile,
.laptop {
  display: none;
}

.v3 {
  @include absolute-center;
  @include col;

  top: 4rem;

  align-items: center;
  gap: 2.125rem;
  justify-content: center;
}
