@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.button {
  align-self: center;

  z-index: 1;

  background: url(~/src/assets/images/desktop/continue.png);
  background-size: 100% 100%;

  &:hover {
    filter: brightness(1.5);
  }
  &:active {
    filter: brightness(0.5);
  }
}

.desktop {
  .button {
    height: 4.688rem;
    width: 15.625rem;
  }
}

.mobile,
.laptop {
  .button {
    height: 17.91rem;
    width: 60.243rem;
  }
}

.v3 {
  .button {
    height: 4.688rem;
    width: 15.625rem;
  }
}
