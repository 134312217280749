@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  flex: 1 0 50%;

  text-align: right;

  span {
    @include font-lato;

    color: #eceaff;
    font-size: 0.938rem;
    letter-spacing: 0.05rem;
    line-height: 1.219rem;
  }
}

.mobile {
  flex: 1 0 50%;

  text-align: right;

  span {
    @include font-lato;

    color: #eceaff;
    font-size: 4.156rem;
    letter-spacing: 0.05rem;
    line-height: 5.435rem;
  }
}

.laptop {
  flex: 1 0 50%;

  text-align: right;

  span {
    @include font-lato;

    color: #eceaff;
    font-size: 2.39835rem;
    letter-spacing: 0.05rem;
    line-height: 4.435rem;
  }
}

.v3 {
  @include row;

  span {
    @include font-lato;

    color: #e5e2fd;
    font-size: 0.8125rem;
    font-weight: 800;
    letter-spacing: 0.05rem;
    line-height: 1.171875rem;
  }
}
