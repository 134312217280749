.carousel.carousel-slider {
  display: flex;

  height: 100%;

  background: #090e21;

  div,
  ul {
    height: 100%;
  }
}

.carousel-root {
  height: 100%;
}
