@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.mobile {
  bottom: 0;
  position: fixed;
  z-index: 2;

  height: calc(100% - 17rem);
  width: 100%;

  overscroll-behavior: contain;
  pointer-events: none;
  transition: height 100ms linear, transform 100ms linear;
  touch-action: none;

  &.live {
    height: calc(100% - 65rem);
  }

  &.edit {
    transform: translate3d(0, 15%, 0);
  }
}

.laptop {
  bottom: 0;
  position: fixed;
  z-index: 2;

  height: calc(100% - 12rem);
  width: 100%;

  overscroll-behavior: contain;
  pointer-events: none;
  transition: height 100ms linear, transform 100ms linear;
  touch-action: none;

  &.live {
    height: calc(100% - 65rem);
  }

  &.edit {
    transform: translate3d(0, 15%, 0);
  }
}
