@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

@keyframes sprite {
  from {
    background-position: 0;
  }
  to {
    background-position: -1875rem;
  }
}

.loader {
  @include row;

  align-items: center;
  gap: 2.5rem;
  justify-content: center;

  left: 0;
  position: absolute;
  top: 0;
  z-index: 1000;

  height: 100%;
  width: 100%;

  background: url(~/src/app/assets/images/loader.webp) no-repeat;
  background-color: #171d2b;
  background-position: center;
  background-size: cover;

  video {
    height: 15.625rem;
    width: 15.625rem;
  }

  img {
    height: 13.25rem;
    width: 37.3125rem;
  }

  .sprite {
    height: 15.625rem;
    width: 15.625rem;

    animation: sprite 2.5s steps(120) infinite;
    background: url(~/src/app/assets/images/sprite.png) no-repeat;
    background-position: 0 center;
    background-size: 1875rem 15.625rem;
  }
}
