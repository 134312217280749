@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop,
.mobile,
.laptop,
.v3 {
  @include col;
  @include font-giorgio-sans-lcg;
}

.hide {
  display: none;
}

.desktop {
  justify-content: flex-end;

  height: 105%;

  > span {
    letter-spacing: 0.04rem;
  }

  .status {
    font-size: 3.75rem;
    line-height: 3.248rem;
  }

  .id {
    font-size: 1.75rem;
    line-height: 1.8rem;
  }
}

.mobile {
  justify-content: center;

  padding-left: 2.5rem;

  .status {
    font-size: 6rem;
    letter-spacing: 0.2rem;
    line-height: 6rem;

    margin-left: 0.5rem;
  }

  .id {
    font-size: 4rem;
    letter-spacing: 0.01rem;
    line-height: 4rem;
  }
}

.laptop {
  justify-content: center;

  padding-left: 1.5rem;

  .status {
    font-size: 4.5rem;
    letter-spacing: 0.1rem;
    line-height: 3.8rem;

    margin-left: 0.5rem;
  }

  .id {
    font-size: 2.7rem;
    letter-spacing: 0.2rem;
    line-height: 3.2rem;
  }
}

.v3 {
  justify-content: center;

  padding-left: 1rem;

  .status {
    font-size: 2.125rem;
    letter-spacing: 0.025rem;
    line-height: 2rem;
    white-space: nowrap;
  }

  .id {
    font-size: 1.25rem;
    letter-spacing: 0.025rem;
    line-height: 1.1rem;
  }
}
