body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url(~/src/assets/images/desktop/offline.png)
    url(~/src/assets/images/desktop/tech-break.png)
    url(~/src/widgets/cars/assets/images/progress-1.png)
    url(~/src/widgets/cars/assets/images/progress-2.png)
    url(~/src/widgets/cars/assets/images/progress-3.png)
    url(~/src/widgets/cars/assets/images/progress-4.png)
    url(~/src/widgets/cars/assets/images/progress-5.png)
    url(~/src/widgets/cars/assets/images/progress-6.png)
    url(~/src/widgets/cars/assets/images/progress-1-finished.png)
    url(~/src/widgets/cars/assets/images/progress-2-finished.png)
    url(~/src/widgets/cars/assets/images/progress-3-finished.png)
    url(~/src/widgets/cars/assets/images/progress-4-finished.png)
    url(~/src/widgets/cars/assets/images/progress-5-finished.png)
    url(~/src/widgets/cars/assets/images/progress-6-finished.png);
}
