@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

$positions: center-center, left-top, left-bottom, right-top, right-bottom;

$max: #7cfa4c;
$max-dark: #627e57;
$min: #e73c27;
$min-dark: #9c483e;

.desktop,
.mobile,
.laptop,
.v3 {
  flex: 1;

  position: relative;

  height: 100%;
  width: 100%;
}

.corner {
  position: absolute;
  right: 0;

  height: 0.6875rem;
  width: 0.6875rem;

  clip-path: polygon(100% 0, 0 0, 100% 100%);

  &.hidden {
    display: none;
  }

  &.round {
    border-radius: 0 0.25rem 0 0;
  }

  &.max {
    background: #7cfa4c;
    box-shadow: 0 0.125rem 0 0 #b1ff93 inset;
  }

  &.min {
    background: #e73c27;
    box-shadow: 0 0.125rem 0 0 #ff705e inset;
  }
}

.data {
  @include col;

  flex: 1;

  align-items: center;
  justify-content: space-between;

  height: 100%;
  width: 100%;
}

.main {
  align-items: center;
  display: flex;
  justify-content: center;

  span {
    @include font-lato;
  }
}

.rate {
  @include row;

  align-items: center;
  justify-content: center;

  span {
    transition: color 300ms linear;
  }
}

.sum {
  @include font-giorgio-sans-lcg;

  align-items: center;
  display: flex;
  justify-content: center;

  height: 100%;
  width: 100%;

  color: #6fe342;
  overflow: hidden;
  transition: height 1000ms linear;
}

.sum.hidden {
  height: 0;

  transition: height 0ms linear;
}

.quotation {
  height: 0.375rem;
  width: 0.75rem;
}

.quotation.hidden {
  display: none;
}

.quotation.up {
  background: #7cfa4c;
  clip-path: polygon(50% 0, 0 100%, 100% 100%);
}

.quotation.down {
  background: #e73c27;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

.top {
  border-radius: 0.3125rem 0.3125rem 0 0;
}

.bottom {
  border-radius: 0 0 0.3125rem 0.3125rem;
}

.left-top {
  border-radius: 0.3125rem 0 0 0;
}

.left-bottom {
  border-radius: 0 0 0 0.3125rem;
}

.right-top {
  border-radius: 0 0.3125rem 0 0;
}

.right-bottom {
  border-radius: 0 0 0.3125rem 0;
}

.desktop {
  padding-bottom: 0.125rem;

  background: linear-gradient(
    180deg,
    #1f2647 0%,
    #202749 19%,
    #20284b 38%,
    #21284c 57%,
    #21294e 76%,
    #222a50 95%
  );
  box-shadow: 0 -0.15625rem 0 0 rgba(9, 16, 32, 1) inset,
    0 0.125rem 0 0 rgba(40, 47, 81, 1) inset;

  .corner {
    display: none;
  }

  .main {
    flex: 1 0 70%;
    gap: 0.5rem;

    padding: 0 0.5rem;

    .quotation {
      height: 0.375rem;
      width: 0.75rem;
    }

    .rate {
      span {
        color: #eceaff;
        font-size: 1.125rem;
      }
    }
  }

  .sum {
    font-size: 1.125rem;
    line-height: 1.225rem;
  }

  &.min {
    .rate {
      span {
        color: #e73c27;
      }
    }
  }

  &.max {
    .rate {
      span {
        color: #84e85e;
      }
    }
  }

  &.selected {
    background: linear-gradient(180deg, #146132 0%, #2bb048 100%);
    box-shadow: 0 -0.09375rem 0 0 rgba(33, 141, 63, 1) inset,
      0 0.125rem 0 0 rgba(76, 234, 168, 1) inset;

    .quotation.down {
      background: #ff7150;
    }

    .quotation.up {
      background: #7cfa4c;
    }

    .rate {
      span {
        color: #eceaff;
      }
    }

    .sum {
      background: rgba(12, 28, 16, 1);
      color: #34fd60;
    }
  }

  &.selected.min {
    .rate {
      span {
        color: #ff7150;
      }
    }
  }

  &.selected.max {
    .rate {
      span {
        color: #84e85e;
      }
    }
  }

  &.disabled {
    background: linear-gradient(180deg, #1e243f 0%, #1f2541 95%);
    box-shadow: 0 -0.15625rem 0 0 rgba(9, 16, 32, 1) inset,
      0 0.125rem 0 0 rgba(40, 47, 81, 1) inset;

    .rate {
      span {
        color: #353f68;
      }
    }
  }

  &.loading {
    background: linear-gradient(180deg, #101423 -20%, #171c2b 95%);
    box-shadow: 0 -0.15625rem 0 0 rgba(9, 16, 32, 1) inset,
      0 0.125rem 0 0 rgba(51, 69, 119, 1) inset;

    .quotation.down {
      background: #6d1105;
    }

    .quotation.up {
      background: #2b542d;
    }

    .rate {
      span {
        color: #4d4f62;
      }
    }

    .sum {
      background: linear-gradient(180deg, #163230 0%, #163935 100%);
      color: #335c3f;
    }
  }

  &.loading.min {
    .rate {
      span {
        color: #6d1105;
      }
    }
  }

  &.loading.max {
    .rate {
      span {
        color: #2b542d;
      }
    }
  }

  &.confirmed {
    background: linear-gradient(180deg, #101423 -20.25%, #171c2b 95%);
    box-shadow: 0 -0.15625rem 0 0 rgba(9, 16, 32, 1) inset,
      0 0.125rem 0 0 rgba(51, 69, 119, 1) inset;

    .quotation.down {
      background: #e73c27;
    }

    .quotation.up {
      background: #7cfa4c;
    }

    .rate {
      span {
        color: #eceaff;
      }
    }

    .sum {
      background: linear-gradient(180deg, #14563a 0%, #146543 100%);
      color: #6fe342;
    }
  }

  &.confirmed.min {
    .rate {
      span {
        color: #e73c27;
      }
    }
  }

  &.confirmed.max {
    .rate {
      span {
        color: #84e85e;
      }
    }
  }

  &:enabled:hover:not(.selected) {
    background: linear-gradient(
      180deg,
      #222a51 0%,
      #232b53 19%,
      #242c56 38%,
      #242e58 57%,
      #252f5b 76%,
      #26305d 95%
    );
    box-shadow: 0 -0.15625rem 0 0 rgba(9, 16, 32, 1) inset,
      0 0.125rem 0 0 rgba(40, 47, 81, 1) inset;
  }

  &:enabled:active:not(.selected) {
    background: linear-gradient(
      180deg,
      #1d2340 -1.65%,
      #1e2442 17.65%,
      #1e2544 37%,
      #1f2546 56.35%,
      #1f2648 75.65%,
      #20274a 95%
    );
    box-shadow: 0 -0.09375rem 0 0 rgba(124, 250, 76, 1) inset;

    .rate {
      span {
        color: #7cfa4c;
      }
    }
  }
}

.mobile {
  padding-bottom: 1rem;

  background: linear-gradient(
    180deg,
    #1f2647 0%,
    #202749 19%,
    #20284b 38%,
    #21284c 57%,
    #21294e 76%,
    #222a50 95%
  );
  box-shadow: 0 -0.7995rem 0 0 rgba(9, 16, 32, 1) inset,
    0 0.63965rem 0 0 rgba(40, 47, 81, 1) inset;

  &.top {
    border-radius: 1.599145rem 1.599145rem 0 0;
  }

  &.bottom {
    border-radius: 0 0 1.599145rem 1.599145rem;
  }

  &.left-top {
    border-radius: 1.599145rem 0 0 0;
  }

  &.left-bottom {
    border-radius: 0 0 0 1.599145rem;
  }

  &.right-top {
    border-radius: 0 1.599145rem 0 0;
  }

  &.right-bottom {
    border-radius: 0 0 1.599145rem 0;
  }

  .corner {
    height: 3.51815rem;
    width: 3.51815rem;

    &.round {
      border-radius: 0 1.27931633335rem 0 0;
    }

    &.max {
      background: #7cfa4c;
      box-shadow: 0 0.63965rem 0 0 #b1ff93 inset;
    }

    &.min {
      background: #e73c27;
      box-shadow: 0 0.63965rem 0 0 #ff705e inset;
    }
  }

  .main {
    flex: 1 0 55%;

    padding: 0 5.323rem;

    .quotation {
      display: none;
    }

    .rate {
      @include row;

      align-items: baseline;
      justify-content: center;

      span {
        color: #eceaff;
        font-size: 4.1575rem;
      }
    }
  }

  .sum {
    align-items: flex-start;

    color: #43ff61;
    font-size: 6.715rem;
    line-height: 4.5rem;
  }

  &.selected {
    background: linear-gradient(
      180deg,
      #175732 0%,
      #195f39 12%,
      #1b6740 24%,
      #1d6f48 36%,
      #1f784f 48%,
      #218057 60%,
      #218057 60%,
      #228659 68%,
      #248b5c 76%,
      #25915e 84%,
      #279661 92%,
      #289c63 100%
    );
    box-shadow: 0 -0.7995rem 0 0 #1a7533 inset, 0 0.63965rem 0 0 #1e7844 inset;

    &:before {
      inset: 0;
      position: absolute;

      height: calc(100% - 0.7995rem);
      width: 100%;

      background: radial-gradient(
        105% 60% at 50% 100%,
        #42f9ac 0%,
        rgba(56, 213, 148, 0.8) 20%,
        rgba(47, 178, 125, 0.6) 40%,
        rgba(38, 145, 102, 0.4) 60%,
        rgba(29, 113, 81, 0.2) 80%,
        rgba(20, 82, 60, 0) 100%
      );
      content: "";
    }

    .rate {
      span {
        color: #eceaff;
      }
    }

    .sum {
      color: #43ff61;
    }
  }

  &.disabled {
    background: linear-gradient(180deg, #1e243f 0%, #1f2541 95%);
    box-shadow: 0 -0.7995rem 0 0 #091020 inset, 0 0.63965rem 0 0 #282f51 inset;

    .rate {
      span {
        color: #353f68;
      }
    }
  }

  &.loading {
    background: linear-gradient(180deg, #101423 -20.25%, #171c2b 95%);
    box-shadow: 0 -0.7995rem 0 0 #091020 inset, 0 0.63965rem 0 0 #1d253a inset;

    .rate {
      span {
        color: #666f93;
      }
    }

    .sum {
      color: #235f3a;
    }
  }

  &.confirmed {
    background: linear-gradient(
      180deg,
      #151b2b 0%,
      #141c29 20%,
      #121e27 40%,
      #101f24 60%,
      #0e2122 80%,
      #0b2220 100%
    );
    box-shadow: 0 -0.7995rem 0 0 #0b0e15 inset, 0 0.63965rem 0 0 #1a253b inset;

    &:before {
      inset: 0;
      position: absolute;

      height: calc(100% - 0.7995rem);
      width: 100%;

      background: radial-gradient(
        61% 60% at 50% 100%,
        rgb(0, 102, 53),
        rgba(13, 39, 24, 0) 100%
      );
      content: "";
    }

    .rate {
      span {
        color: #eceaff;
      }
    }

    .sum {
      color: #43ff61;
    }
  }

  &:enabled:hover:not(.selected) {
    background: linear-gradient(
      180deg,
      #222a51 0%,
      #232b53 19%,
      #242c56 38%,
      #242e58 57%,
      #252f5b 76%,
      #26305d 95%
    );
    box-shadow: 0 -0.7995rem 0 0 #091020 inset, 0 0.63965rem 0 0 #282f51 inset;
  }

  &:enabled:active:not(.selected) {
    background: linear-gradient(
      180deg,
      #1d2340 -1.65%,
      #1e2442 17.65%,
      #1e2544 37%,
      #1f2546 56.35%,
      #1f2648 75.65%,
      #20274a 95%
    );
    box-shadow: 0 -0.479743625rem 0 0 #7cfa4c inset;

    .rate {
      span {
        color: #7cfa4c;
      }
    }
  }
}

.laptop {
  padding-bottom: 1rem;

  background: linear-gradient(
    180deg,
    #1f2647 0%,
    #202749 19%,
    #20284b 38%,
    #21284c 57%,
    #21294e 76%,
    #222a50 95%
  );
  box-shadow: 0 -0.6rem 0 0 rgba(9, 16, 32, 1) inset,
    0 0.48065rem 0 0 rgba(40, 47, 81, 1) inset;

  &.top {
    border-radius: 0.96125rem 0.96125rem 0 0;
  }

  &.bottom {
    border-radius: 0 0 0.96125rem 0.96125rem;
  }

  &.left-top {
    border-radius: 0.96125rem 0 0 0;
  }

  &.left-bottom {
    border-radius: 0 0 0 0.96125rem;
  }

  &.right-top {
    border-radius: 0 0.96125rem 0 0;
  }

  &.right-bottom {
    border-radius: 0 0 0.96125rem 0;
  }

  .corner {
    height: 2.641935rem;
    width: 2.641935rem;

    &.round {
      border-radius: 0 0.96125rem 0 0;
    }

    &.max {
      background: #7cfa4c;
      box-shadow: 0 0.48065rem 0 0 #b1ff93 inset;
    }

    &.min {
      background: #e73c27;
      box-shadow: 0 0.48065rem 0 0 #ff705e inset;
    }
  }

  .main {
    flex: 1 0 55%;

    padding: 0 5.323rem;

    .quotation {
      display: none;
    }

    .rate {
      @include row;

      align-items: baseline;
      justify-content: center;

      span {
        color: #eceaff;
        font-size: 3.1225rem;
      }
    }
  }

  .sum {
    align-items: flex-start;

    color: #43ff61;
    font-size: 5.045rem;
    line-height: 4.5rem;
  }

  &.selected {
    background: linear-gradient(
      180deg,
      #175732 0%,
      #195f39 12%,
      #1b6740 24%,
      #1d6f48 36%,
      #1f784f 48%,
      #218057 60%,
      #218057 60%,
      #228659 68%,
      #248b5c 76%,
      #25915e 84%,
      #279661 92%,
      #289c63 100%
    );
    box-shadow: 0 -0.6rem 0 0 #1a7533 inset, 0 0.48065rem 0 0 #1e7844 inset;

    &:before {
      inset: 0;
      position: absolute;

      height: calc(100% - 0.7995rem);
      width: 100%;

      background: radial-gradient(
        105% 60% at 50% 100%,
        #42f9ac 0%,
        rgba(56, 213, 148, 0.8) 20%,
        rgba(47, 178, 125, 0.6) 40%,
        rgba(38, 145, 102, 0.4) 60%,
        rgba(29, 113, 81, 0.2) 80%,
        rgba(20, 82, 60, 0) 100%
      );
      content: "";
    }

    .rate {
      span {
        color: #eceaff;
      }
    }

    .sum {
      color: #43ff61;
    }
  }

  &.disabled {
    background: linear-gradient(180deg, #1e243f 0%, #1f2541 95%);
    box-shadow: 0 -0.6rem 0 0 #091020 inset, 0 0.48065rem 0 0 #282f51 inset;

    .rate {
      span {
        color: #353f68;
      }
    }
  }

  &.loading {
    background: linear-gradient(180deg, #101423 -20.25%, #171c2b 95%);
    box-shadow: 0 -0.6rem 0 0 #091020 inset, 0 0.48065rem 0 0 #1d253a inset;

    .rate {
      span {
        color: #666f93;
      }
    }

    .sum {
      color: #235f3a;
    }
  }

  &.confirmed {
    background: linear-gradient(
      180deg,
      #151b2b 0%,
      #141c29 20%,
      #121e27 40%,
      #101f24 60%,
      #0e2122 80%,
      #0b2220 100%
    );
    box-shadow: 0 -0.6rem 0 0 #0b0e15 inset, 0 0.48065rem 0 0 #1a253b inset;

    &:before {
      inset: 0;
      position: absolute;

      height: calc(100% - 0.7995rem);
      width: 100%;

      background: radial-gradient(
        61% 60% at 50% 100%,
        rgb(0, 102, 53),
        rgba(13, 39, 24, 0) 100%
      );
      content: "";
    }

    .rate {
      span {
        color: #eceaff;
      }
    }

    .sum {
      color: #43ff61;
    }
  }

  &:enabled:hover:not(.selected) {
    background: linear-gradient(
      180deg,
      #222a51 0%,
      #232b53 19%,
      #242c56 38%,
      #242e58 57%,
      #252f5b 76%,
      #26305d 95%
    );
    box-shadow: 0 -0.6rem 0 0 #091020 inset, 0 0.48065rem 0 0 #282f51 inset;
  }

  &:enabled:active:not(.selected) {
    background: linear-gradient(
      180deg,
      #1d2340 -1.65%,
      #1e2442 17.65%,
      #1e2544 37%,
      #1f2546 56.35%,
      #1f2648 75.65%,
      #20274a 95%
    );
    box-shadow: 0 -0.35rem 0 0 #7cfa4c inset;

    .rate {
      span {
        color: #7cfa4c;
      }
    }
  }
}

.v3 {
  padding-bottom: 0.125rem;

  background: linear-gradient(
    180deg,
    #1f2647 0%,
    #202749 19%,
    #20284b 38%,
    #21284c 57%,
    #21294e 76%,
    #222a50 95%
  );
  box-shadow: 0 -0.15625rem 0 0 #091020 inset, 0 0.125rem 0 0 #282f51 inset;

  .main {
    flex: 1 0 55%;
    gap: 0.5rem;

    padding: 0 0.5rem;

    .quotation {
      display: none;
    }

    .rate {
      @include row;

      align-items: baseline;
      gap: 0.0625rem;
      justify-content: center;

      span {
        color: #eceaff;
      }
    }
  }

  .sum {
    align-items: flex-start;

    position: relative;
    z-index: 1;

    color: #43ff61;
    font-size: 1.5rem;
    line-height: 1.25rem;
  }

  &.selected {
    background: linear-gradient(
      180deg,
      #175732 0%,
      #195f39 12%,
      #1b6740 24%,
      #1d6f48 36%,
      #1f784f 48%,
      #218057 60%,
      #218057 60%,
      #228659 68%,
      #248b5c 76%,
      #25915e 84%,
      #279661 92%,
      #289c63 100%
    );
    box-shadow: 0 -0.15625rem 0 0 #1a7533 inset, 0 0.125rem 0 0 #1e7844 inset;

    &:before {
      inset: 0;
      position: absolute;

      height: calc(100% - 0.15625rem);
      width: 100%;

      background: radial-gradient(
        105% 60% at 50% 100%,
        #42f9ac 0%,
        rgba(56, 213, 148, 0.8) 20%,
        rgba(47, 178, 125, 0.6) 40%,
        rgba(38, 145, 102, 0.4) 60%,
        rgba(29, 113, 81, 0.2) 80%,
        rgba(20, 82, 60, 0) 100%
      );
      content: "";
    }

    .rate {
      span {
        color: #eceaff;
      }
    }

    .sum {
      color: #43ff61;
    }
  }

  &.disabled {
    background: linear-gradient(180deg, #1e243f 0%, #1f2541 95%);
    box-shadow: 0 -0.15625rem 0 0 #091020 inset, 0 0.125rem 0 0 #282f51 inset;

    .rate {
      span {
        color: #353f68;
      }
    }
  }

  &.loading {
    background: linear-gradient(180deg, #101423 -20.25%, #171c2b 95%);
    box-shadow: 0 -0.15625rem 0 0 #091020 inset, 0 0.125rem 0 0 #1d253a inset;

    .rate {
      span {
        color: #666f93;
      }
    }

    .sum {
      color: #235f3a;
    }
  }

  &.confirmed {
    background: linear-gradient(
      180deg,
      #151b2b 0%,
      #141c29 20%,
      #121e27 40%,
      #101f24 60%,
      #0e2122 80%,
      #0b2220 100%
    );
    box-shadow: 0 -0.15625rem 0 0 #0b0e15 inset, 0 0.125rem 0 0 #1a253b inset;

    &:before {
      inset: 0;
      position: absolute;

      height: calc(100% - 0.15625rem);
      width: 100%;

      background: radial-gradient(
        61% 60% at 50% 100%,
        rgb(0, 102, 53),
        rgba(13, 39, 24, 0) 100%
      );
      content: "";
    }

    .rate {
      span {
        color: #eceaff;
      }
    }

    .sum {
      color: #43ff61;
    }
  }

  &.win {
    justify-content: center;

    background: url(~/src/components/odd/assets/images/v3/common/win.png) no-repeat;
    background-size: 100% 100%;
    box-shadow: 0 -0.15625rem 0 0 rgba(95, 64, 2, 1) inset,
      0 0.125rem 0 0 rgba(242, 197, 104, 1) inset,
      0 0 2.5rem 0 rgba(249, 219, 116, 0.3) inset;

    .win {
      @include col;

      span {
        &:first-child {
          @include font-lato;

          color: #6d4a04;
          font-size: 0.75rem;
          font-style: italic;
          font-weight: 800;
          letter-spacing: 0.02rem;
          line-height: 0.95rem;
        }

        &:last-child {
          @include font-giorgio-sans-lcg;

          color: #312408;
          font-size: 1.5rem;
          line-height: 1.3rem;
        }
      }
    }
  }

  &.loss {
    background: linear-gradient(180deg, #13162c 0%, #1a1f37 95%);
    box-shadow: 0 -0.15625rem 0 0 #091020 inset, 0 0.125rem 0 0 #1e243e inset;

    .rate {
      span {
        color: #2b3454;
      }
    }

    .sum {
      color: #0c0e1c;
    }
  }

  &:enabled:hover:not(.selected) {
    background: linear-gradient(
      180deg,
      #222a51 0%,
      #232b53 19%,
      #242c56 38%,
      #242e58 57%,
      #252f5b 76%,
      #26305d 95%
    );
    box-shadow: 0 -0.15625rem 0 0 #091020 inset, 0 0.125rem 0 0 #282f51 inset;
  }

  &:enabled:active:not(.selected) {
    background: linear-gradient(
      180deg,
      #1d2340 -1.65%,
      #1e2442 17.65%,
      #1e2544 37%,
      #1f2546 56.35%,
      #1f2648 75.65%,
      #20274a 95%
    );
    box-shadow: 0 -0.09375rem 0 0 #7cfa4c inset;

    .rate {
      span {
        color: #7cfa4c;
      }
    }
  }
}

.v3.dark {
  padding-bottom: 0.125rem;

  background: linear-gradient(
    180deg,
    #191a28 0%,
    #1a1c2a 16%,
    #1b1d2c 32%,
    #1d1f2f 48%,
    #1e2031 64%,
    #1f2233 80%,
    #1f2233 80%,
    #202334 84%,
    #212436 88%,
    #212537 92%,
    #222639 96%,
    #23273a 100%
  );
  box-shadow: 0 -0.15625rem 0 0 rgba(15, 19, 28, 1) inset,
    0 0.125rem 0 0 rgba(35, 36, 53, 1) inset;

  .main {
    flex: 1 0 55%;
    gap: 0.5rem;

    padding: 0 0.5rem;

    .quotation {
      display: none;
    }

    .rate {
      @include row;

      align-items: baseline;
      gap: 0.0625rem;
      justify-content: center;

      span {
        color: #595b6f;
        font-size: 0.625rem;

        &:first-child {
          color: #fefdff;
          font-size: 1rem;
        }
      }
    }
  }

  .sum {
    align-items: flex-start;

    color: #43bbff;
    font-size: 1.5rem;
    line-height: 1.25rem;
  }

  &.selected {
    background: linear-gradient(
      180deg,
      #211b5b 0%,
      #26216a 20%,
      #2b277a 40%,
      #302d8a 60%,
      #36349a 95%,
      #27236e 100%
    );
    box-shadow: 0 -0.15625rem 0 0 rgba(26, 29, 117, 1) inset,
      0 0.125rem 0 0 rgba(61, 107, 221, 1) inset;

    &:before {
      inset: 0;
      position: absolute;

      height: calc(100% - 0.15625rem);
      width: 100%;

      background: radial-gradient(
        105% 60.15% at 50% 100%,
        #4247f9 0%,
        rgba(58, 60, 213, 0.8) 20%,
        rgba(49, 50, 178, 0.6) 40%,
        rgba(41, 39, 145, 0.4) 60%,
        rgba(32, 29, 113, 0.2) 80%,
        rgba(24, 20, 82, 0) 100%
      );
      content: "";
    }

    .rate {
      span {
        color: #697daf;

        &:first-child {
          color: #fefdff;
        }
      }
    }

    .sum {
      color: #43bbff;
    }
  }

  &.disabled {
    background: rgba(21, 22, 34, 1);
    box-shadow: 0 -0.15625rem 0 0 rgba(15, 19, 28, 1) inset,
      0 0.125rem 0 0 rgba(35, 36, 53, 1) inset;

    .rate {
      span {
        color: #1f2330;

        &:first-child {
          color: #595a5e;
        }
      }
    }
  }

  &.loading {
    background: linear-gradient(
      180deg,
      #121418 0%,
      #121419 12.89%,
      #12141a 25.79%,
      #11141a 38.68%,
      #11141b 51.58%,
      #11141c 64.47%,
      #11141c 64.47%,
      #12161e 71.58%,
      #141720 78.68%,
      #151823 85.79%,
      #161a25 92.89%,
      #171b27 100%
    );
    box-shadow: 0 -0.15625rem 0 0 rgba(11, 14, 21, 1) inset,
      0 0.125rem 0 0 rgba(29, 35, 52, 1) inset;

    .rate {
      span {
        color: #1f2330;

        &:first-child {
          color: #595a5e;
        }
      }
    }

    .sum {
      color: #384d85;
    }
  }

  &.confirmed {
    background: linear-gradient(
      180deg,
      #121418 0%,
      #121419 12.89%,
      #12141a 25.79%,
      #11141a 38.68%,
      #11141b 51.58%,
      #11141c 64.47%,
      #11141c 64.47%,
      #12161e 71.58%,
      #141720 78.68%,
      #151823 85.79%,
      #161a25 92.89%,
      #171b27 100%
    );
    box-shadow: 0 -0.15625rem 0 0 rgba(11, 14, 21, 1) inset,
      0 0.125rem 0 0 rgba(29, 35, 52, 1) inset;

    &:before {
      inset: 0;
      position: absolute;

      height: calc(100% - 0.15625rem);
      width: 100%;

      background: radial-gradient(
        61.25% 60.15% at 50% 100%,
        #292b89 0%,
        rgba(38, 38, 126, 0.8) 20%,
        rgba(34, 34, 114, 0.6) 40%,
        rgba(31, 29, 103, 0.4) 60%,
        rgba(27, 24, 93, 0.2) 80%,
        rgba(24, 20, 82, 0) 100%
      );
      content: "";
    }

    .rate {
      span {
        color: #3d4660;

        &:first-child {
          color: #fefdff;
        }
      }
    }

    .sum {
      color: #4377ff;
    }
  }

  &.win {
    justify-content: center;

    background: url(~/src/components/odd/assets/images/v3/common/win.png) no-repeat;
    background-size: 100% 100%;
    box-shadow: 0 -0.15625rem 0 0 rgba(95, 64, 2, 1) inset,
      0 0.125rem 0 0 rgba(242, 197, 104, 1) inset,
      0 0 2.5rem 0 rgba(249, 219, 116, 0.3) inset;

    .win {
      @include col;

      span {
        &:first-child {
          @include font-lato;

          color: #6d4a04;
          font-size: 0.75rem;
          font-style: italic;
          font-weight: 800;
          letter-spacing: 0.02rem;
          line-height: 0.95rem;
        }

        &:last-child {
          @include font-giorgio-sans-lcg;

          color: #312408;
          font-size: 1.5rem;
          line-height: 1.3rem;
        }
      }
    }
  }

  &.loss {
    background: linear-gradient(
      180deg,
      #121418 0%,
      #121419 12.89%,
      #12141a 25.79%,
      #11141a 38.68%,
      #11141b 51.58%,
      #11141c 64.47%,
      #11141c 64.47%,
      #12161e 71.58%,
      #141720 78.68%,
      #151823 85.79%,
      #161a25 92.89%,
      #171b27 100%
    );
    box-shadow: 0 -0.15625rem 0 0 rgba(11, 14, 21, 1) inset,
      0 0.125rem 0 0 rgba(29, 35, 52, 1) inset;

    .rate {
      span {
        color: #1f2330;

        &:first-child {
          color: #595a5e;
        }
      }
    }

    .sum {
      color: #384d85;
    }
  }

  &:enabled:hover:not(.selected) {
    background: linear-gradient(
      180deg,
      #23253c 0%,
      #23253c 12.89%,
      #23263c 25.79%,
      #24263d 38.68%,
      #24273d 51.58%,
      #24273d 64.47%,
      #24273d 64.47%,
      #25283d 71.58%,
      #25293d 78.68%,
      #26293e 85.79%,
      #262a3e 92.89%,
      #272b3e 100%
    );
    box-shadow: 0 -0.15625rem 0 0 rgba(15, 19, 28, 1) inset,
      0 0.125rem 0 0 rgba(43, 44, 72, 1) inset;
  }

  &:enabled:active:not(.selected) {
    background: linear-gradient(
      0deg,
      #1e2138 0%,
      #1d2036 6.17%,
      #1d1f33 12.34%,
      #1c1f31 18.51%,
      #1c1e2e 24.68%,
      #1b1d2c 30.85%,
      #1b1d2c 30.85%,
      #1b1c2b 44.68%,
      #1a1c2a 58.51%,
      #1a1b2a 72.34%,
      #191b29 86.17%,
      #191a28 100%
    );
    box-shadow: 0 -0.15625rem 0 0 rgba(111, 152, 255, 1) inset,
      0 0.125rem 0 0 rgba(35, 36, 53, 1) inset;

    .rate {
      span {
        color: #465c92;

        &:first-child {
          color: #6f98ff;
        }
      }
    }
  }
}

.v3.markets {
  padding-bottom: 0.125rem;

  background: linear-gradient(
    180deg,
    #1f2647 0%,
    #202749 19%,
    #20284b 38%,
    #21284c 57%,
    #21294e 76%,
    #222a50 95%
  );
  box-shadow: 0 -0.15625rem 0 0 #091020 inset, 0 0.125rem 0 0 #282f51 inset;

  .main {
    flex: 1 0 55%;
    gap: 0.5rem;

    padding: 0 0.5rem;

    .quotation {
      display: none;
    }

    .rate {
      @include row;

      align-items: baseline;
      gap: 0.0625rem;
      justify-content: center;

      span {
        color: #eceaff;
        font-size: 1.0625rem;
      }
    }
  }

  .sum {
    align-items: flex-start;

    color: #43ff61;
    font-size: 1.75rem;
    line-height: 1.25rem;
  }

  &.selected {
    background: linear-gradient(
      180deg,
      #175732 0%,
      #195f39 12%,
      #1b6740 24%,
      #1d6f48 36%,
      #1f784f 48%,
      #218057 60%,
      #218057 60%,
      #228659 68%,
      #248b5c 76%,
      #25915e 84%,
      #279661 92%,
      #289c63 100%
    );
    box-shadow: 0 -0.15625rem 0 0 #1a7533 inset, 0 0.125rem 0 0 #1e7844 inset;

    &:before {
      inset: 0;
      position: absolute;

      height: calc(100% - 0.15625rem);
      width: 100%;

      background: radial-gradient(
        105% 60% at 50% 100%,
        #42f9ac 0%,
        rgba(56, 213, 148, 0.8) 20%,
        rgba(47, 178, 125, 0.6) 40%,
        rgba(38, 145, 102, 0.4) 60%,
        rgba(29, 113, 81, 0.2) 80%,
        rgba(20, 82, 60, 0) 100%
      );
      content: "";
    }

    .rate {
      span {
        color: #eceaff;
      }
    }

    .sum {
      color: #43ff61;
    }
  }

  &.disabled {
    background: linear-gradient(180deg, #1e243f 0%, #1f2541 95%);
    box-shadow: 0 -0.15625rem 0 0 #091020 inset, 0 0.125rem 0 0 #282f51 inset;

    .rate {
      span {
        color: #353f68;
      }
    }
  }

  &.loading {
    background: linear-gradient(180deg, #101423 -20.25%, #171c2b 95%);
    box-shadow: 0 -0.15625rem 0 0 #091020 inset, 0 0.125rem 0 0 #1d253a inset;

    .rate {
      span {
        color: #666f93;
      }
    }

    .sum {
      color: #235f3a;
    }
  }

  &.confirmed {
    background: linear-gradient(
      180deg,
      #151b2b 0%,
      #141c29 20%,
      #121e27 40%,
      #101f24 60%,
      #0e2122 80%,
      #0b2220 100%
    );
    box-shadow: 0 -0.15625rem 0 0 #0b0e15 inset, 0 0.125rem 0 0 #1a253b inset;

    &:before {
      inset: 0;
      position: absolute;

      height: calc(100% - 0.15625rem);
      width: 100%;

      background: radial-gradient(
        61% 60% at 50% 100%,
        rgb(0, 102, 53),
        rgba(13, 39, 24, 0) 100%
      );
      content: "";
    }

    .rate {
      span {
        color: #eceaff;
      }
    }

    .sum {
      color: #43ff61;
    }
  }

  &.win {
    justify-content: center;

    background: url(~/src/components/odd/assets/images/v3/common/win.png) no-repeat;
    background-size: 100% 100%;
    box-shadow: 0 -0.15625rem 0 0 rgba(95, 64, 2, 1) inset,
      0 0.125rem 0 0 rgba(242, 197, 104, 1) inset,
      0 0 2.5rem 0 rgba(249, 219, 116, 0.3) inset;

    .win {
      @include col;

      span {
        &:first-child {
          @include font-lato;

          color: #6d4a04;
          font-size: 0.875rem;
          font-style: italic;
          font-weight: 800;
          letter-spacing: 0.02rem;
          line-height: 1.5rem;
        }

        &:last-child {
          @include font-giorgio-sans-lcg;

          color: #312408;
          font-size: 1.75rem;
          line-height: 1.3rem;
        }
      }
    }
  }

  &.loss {
    background: linear-gradient(180deg, #13162c 0%, #1a1f37 95%);
    box-shadow: 0 -0.15625rem 0 0 #091020 inset, 0 0.125rem 0 0 #1e243e inset;

    .rate {
      span {
        color: #2b3454;
      }
    }

    .sum {
      color: #0c0e1c;
    }
  }

  &:enabled:hover:not(.selected) {
    background: linear-gradient(
      180deg,
      #222a51 0%,
      #232b53 19%,
      #242c56 38%,
      #242e58 57%,
      #252f5b 76%,
      #26305d 95%
    );
    box-shadow: 0 -0.15625rem 0 0 #091020 inset, 0 0.125rem 0 0 #282f51 inset;
  }

  &:enabled:active:not(.selected) {
    background: linear-gradient(
      180deg,
      #1d2340 -1.65%,
      #1e2442 17.65%,
      #1e2544 37%,
      #1f2546 56.35%,
      #1f2648 75.65%,
      #20274a 95%
    );
    box-shadow: 0 -0.09375rem 0 0 #7cfa4c inset;

    .rate {
      span {
        color: #7cfa4c;
      }
    }
  }
}
