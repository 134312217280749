@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

@keyframes desktop {
  0% {
    background: url(~/src/components/limit/assets/images/desktop/default.png);
    background-size: contain;
  }
  25% {
    background: url(~/src/components/limit/assets/images/desktop/active.png);
    background-size: contain;
  }
  50% {
    background: url(~/src/components/limit/assets/images/desktop/default.png);
    background-size: contain;
  }
  75% {
    background: url(~/src/components/limit/assets/images/desktop/active.png);
    background-size: contain;
  }
  100% {
    background: url(~/src/components/limit/assets/images/desktop/default.png);
    background-size: contain;
  }
}

@keyframes v3 {
  0% {
    color: #435189;
  }
  25% {
    color: #eceaff;
  }
  50% {
    color: #435189;
  }
  75% {
    color: #eceaff;
  }
  100% {
    color: #435189;
  }
}

@keyframes v3-dark {
  0% {
    background: url(~/src/components/limit/assets/images/v3/dark/default.png);
    background-size: 100% 100%;
  }
  25% {
    background: url(~/src/components/limit/assets/images/v3/dark/active.png);
    background-size: 100% 100%;
  }
  50% {
    background: url(~/src/components/limit/assets/images/v3/dark/default.png);
    background-size: 100% 100%;
  }
  75% {
    background: url(~/src/components/limit/assets/images/v3/dark/active.png);
    background-size: 100% 100%;
  }
  100% {
    background: url(~/src/components/limit/assets/images/v3/dark/default.png);
    background-size: 100% 100%;
  }
}

.desktop {
  @include row;
  @include font-giorgio-sans-lcg;

  align-items: flex-end;
  gap: 0.375rem;
  justify-content: center;

  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;

  height: 2.25rem;
  width: 14.8125rem;

  background: url(~/src/components/limit/assets/images/desktop/default.png);
  background-size: contain;
  color: #435189;
  font-size: 1.125rem;
  line-height: 1.825rem;

  &.active {
    animation: desktop 2s 1 linear;
  }
}

.mobile {
  display: none;
}

.v3 {
  @include row;
  @include font-giorgio-sans-lcg;

  align-items: center;
  gap: 0.25rem;
  justify-content: center;

  bottom: -0.15rem;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;

  height: 1.3rem;
  width: 9.8125rem;

  color: #435189;
  font-size: 0.8125rem;
  line-height: 2.825rem;

  &.active {
    animation: v3 2s 1 linear;
  }

  &.filter {
    filter: brightness(0.65);
  }
}

.v3.dark {
  @include row;
  @include font-giorgio-sans-lcg;

  align-items: flex-end;
  gap: 0.25rem;
  justify-content: center;

  bottom: 0.1rem;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;

  height: 1.165rem;
  width: 9.8125rem;

  background: url(~/src/components/limit/assets/images/v3/dark/default.png);
  background-size: 100% 100%;
  font-size: 0.8125rem;
  line-height: 1rem;

  span {
    color: #eceaff;

    &:first-child {
      color: #435189;
    }
  }

  &.active {
    animation: v3-dark 2s 1 linear;
  }
}

.v3.markets {
  @include row;
  @include font-giorgio-sans-lcg;

  align-items: center;
  gap: 0.25rem;
  justify-content: center;

  bottom: -0.15rem;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;

  height: 1.3rem;
  width: 9.8125rem;

  color: #8c92d1;
  font-size: 0.8125rem;
  line-height: 2.825rem;

  &.active {
    animation: v3 2s 1 linear;
  }
}
