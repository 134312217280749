@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  display: none;
}

.mobile {
  display: none;
}

.v3 {
  height: 1.75rem;
  width: 1.3125rem;
}
