@use "sass:map";

@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";
@import "src/shared/styles/variables.scss";

.line {
  color: #ffffff;
}

.line.even {
  color: #000000;
}

.lines {
  @include row;
  @include font-giorgio-sans-lcg;

  align-items: center;
  justify-content: space-around;
}

@for $i from 1 through 6 {
  .lines-#{$i} {
    background: map.get($colors, $i);
  }

  @for $j from 1 through 6 {
    .lines-#{$i}-#{$j} {
      background: linear-gradient(
        95deg,
        map.get($colors, $i) 0%,
        map.get($colors, $i) 50%,
        map.get($colors, $j) 50%
      );
    }

    @for $k from 1 through 6 {
      .lines-#{$i}-#{$j}-#{$k} {
        background: linear-gradient(
          to right,
          map.get($colors, $i) 0%,
          map.get($colors, $i) 33%,
          map.get($colors, $j) 33%,
          map.get($colors, $j) 66%,
          map.get($colors, $k) 66%
        );
      }
    }
  }
}

.type {
  align-items: center;
  display: flex;
  flex: 1 0 50%;
  justify-content: center;

  background: url(~/src/assets/images/mobile/flag.png) no-repeat;
  background-color: #0b0f38;
  background-size: 100% 100%;
}

.desktop {
  display: none;
}

.mobile,
.laptop {
  @include row;

  gap: 0.639rem;

  width: 100%;

  .data {
    @include row;

    width: 42.857rem;

    > div {
      flex: 1 0 50%;
    }
  }

  .line {
    font-size: 10.675rem;
  }

  .type {
    img {
      height: 7.35925rem;
    }
  }

  .sum {
    @include font-lato;

    flex: 1;

    padding-bottom: 1.992rem;
    padding-right: 2.974rem;
    padding-top: 2.165rem;

    background: #070e4a;
    color: #ffffff;
    font-size: 6.396rem;
    font-weight: 800;
    text-align: right;
  }
}

.v3 {
  @include row;

  align-self: flex-end;
  gap: 0.125rem;

  height: 100%;
  width: 100%;

  .data {
    @include row;

    width: 15.25rem;

    > div {
      flex: 1 0 50%;
    }
  }

  .line {
    font-size: 3.806875rem;
  }

  .type {
    img {
      height: 2.625rem;
    }
  }

  .sum {
    @include font-lato;

    flex: 1;

    padding-bottom: 1.992rem;
    padding-right: 2.974rem;
    padding-top: 2.165rem;

    background: #070e4a;
    color: #ffffff;
    font-size: 2rem;
    font-weight: 800;
    line-height: 0rem;
    text-align: right;
  }
}
