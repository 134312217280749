@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  display: none;
}

.mobile {
  height: 9.315556rem;
  width: 6rem;
}

.laptop {
  height: 5rem;
  width: 5rem;
}

.v3 {
  height: 1.625rem;
  width: 1.5rem;
}
