@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.desktop {
  align-items: flex-end;
  display: flex;
  justify-content: center;

  bottom: 2rem;
  position: absolute;
  right: 2.125rem;
  z-index: 1;

  height: 11.813rem;
  width: 21.75rem;

  overflow: hidden;

  background: url(~/src/components/bet/assets/images/desktop/default.png) no-repeat;
  background-size: contain;

  &.basic {
    .count {
      &:first-child {
        display: none;
      }
    }
  }

  &.disabled {
    background: url(~/src/components/bet/assets/images/desktop/disabled.png)
      no-repeat;
    background-size: contain;

    .count {
      color: #0e162d;
      letter-spacing: 0.313rem;
    }
  }

  &.loading {
    background: url(~/src/components/bet/assets/images/desktop/loading.png) no-repeat;
    background-size: contain;

    .count {
      opacity: 0.2;

      &:first-child {
        display: none;
      }
    }
  }

  &.error {
    background: url(~/src/components/bet/assets/images/desktop/error.png) no-repeat;
    background-size: contain;
  }

  &:enabled:hover {
    background: url(~/src/components/bet/assets/images/desktop/hover.png) no-repeat;
    background-size: contain;
  }

  &:enabled:active {
    background: url(~/src/components/bet/assets/images/desktop/active.png) no-repeat;
    background-size: contain;
  }

  .info {
    @include row;

    gap: 0.7rem;

    bottom: 1.8rem;
    left: 6.6rem;
    position: absolute;
  }

  .count {
    @include font-lato;

    font-size: 1rem;
    color: #b85af6;
    font-style: italic;
    font-weight: 800;
    letter-spacing: 0.55rem;
  }

  .message {
    @include row;
    @include absolute-center;
    @include font-giorgio-sans-lcg;

    align-items: center;
    justify-content: center;

    z-index: 1;

    width: 12.5rem;

    color: #e0dbd0;
    font-size: 2.625rem;
    line-height: 2.231rem;
  }

  .icon {
    @include absolute-center;

    height: 5rem;
    width: 5rem;

    display: block;

    animation: rotate 1000ms infinite linear;
    z-index: 1;
  }
}

.mobile {
  align-items: center;
  align-self: center;
  display: flex;
  gap: 3.836rem;
  justify-content: center;

  position: relative;
  z-index: 1;

  height: 17.904rem;
  width: 104.54rem;

  background: url(~/src/components/bet/assets/images/mobile/default.png);
  background-size: 100% 100%;

  &.disabled {
    background: url(~/src/components/bet/assets/images/mobile/disabled.png);
    background-size: 100% 100%;

    .count {
      color: #364272;
      letter-spacing: 0.313rem;
    }
  }

  &.loading {
    background: url(~/src/components/bet/assets/images/mobile/loading.png);
    background-size: 100% 100%;

    .count {
      opacity: 0.2;
    }
  }

  &.error {
    padding-left: 6rem;

    background: url(~/src/components/bet/assets/images/mobile/error.png);
    background-size: 100% 100%;
  }

  &:enabled:active {
    background: url(~/src/components/bet/assets/images/mobile/active.png);
    background-size: 100% 100%;
  }

  .info {
    @include row;

    gap: 3rem;
  }

  .count {
    @include font-giorgio-sans-lcg;

    color: #eceaff;
    font-size: 7.673rem;
    line-height: 6rem;
  }

  .message {
    @include row;
    @include font-giorgio-sans-lcg;

    align-items: center;
    justify-content: center;

    z-index: 1;

    color: #e9eaed;
    font-size: 7.673rem;
    text-transform: uppercase;
  }

  .icon {
    @include absolute-center;

    height: 9.911rem;
    width: 9.911rem;

    display: block;

    animation: rotate 1000ms infinite linear;
    z-index: 1;
  }
}

.mobile.secondary {
  align-self: auto;

  height: 10.93495rem;
  width: 23.4114rem;

  background: url(~/src/components/bet/assets/images/mobile/secondary/button-bet-mobile-default.png)
    no-repeat;
  background-size: 100% 100%;

  &.disabled {
    background: url(~/src/components/bet/assets/images/mobile/secondary/button-bet-mobile-disabled.png)
      no-repeat;
    background-size: 100% 100%;
  }

  &.loading {
    background: url(~/src/components/bet/assets/images/mobile/secondary/button-bet-mobile-loading.png)
      no-repeat;
    background-size: 100% 100%;
  }

  &.error {
    background: url(~/src/components/bet/assets/images/mobile/secondary/button-bet-mobile-error.png)
      no-repeat;
    background-size: 100% 100%;
  }

  &:enabled:active {
    background: url(~/src/components/bet/assets/images/mobile/secondary/button-bet-mobile-active.png)
      no-repeat;
    background-size: 100% 100%;
  }

  .icon {
    @include absolute-center;

    height: 7.2025rem;
    width: 7.2025rem;

    display: block;

    animation: rotate 1000ms infinite linear;
    z-index: 1;
  }

  .info,
  .count,
  .message {
    display: none;
  }
}

.laptop {
  align-items: center;
  align-self: center;
  display: flex;
  gap: 3.836rem;
  justify-content: center;

  position: relative;
  z-index: 1;

  height: 100%;
  width: 100%;

  background: url(~/src/components/bet/assets/images/mobile/default.png);
  background-size: 100% 100%;

  &.disabled {
    background: url(~/src/components/bet/assets/images/mobile/disabled.png);
    background-size: 100% 100%;

    .count {
      color: #364272;
      letter-spacing: 0.313rem;
    }
  }

  &.loading {
    background: url(~/src/components/bet/assets/images/mobile/loading.png);
    background-size: 100% 100%;

    .count {
      opacity: 0.2;
    }
  }

  &.error {
    padding-left: 9rem;

    background: url(~/src/components/bet/assets/images/mobile/error.png);
    background-size: 100% 100%;
  }

  &:enabled:active {
    background: url(~/src/components/bet/assets/images/mobile/active.png);
    background-size: 100% 100%;
  }

  .info {
    @include row;

    gap: 1rem;
  }

  .count {
    @include font-giorgio-sans-lcg;

    color: #eceaff;
    font-size: 4.429025rem;
    line-height: 6rem;
  }

  .message {
    @include row;
    @include font-giorgio-sans-lcg;

    align-items: center;
    justify-content: center;

    z-index: 1;

    color: #e9eaed;
    font-size: 4.429025rem;
    text-transform: uppercase;
  }

  .icon {
    @include absolute-center;

    width: 5rem;

    display: block;

    animation: rotate 1000ms infinite linear;
    z-index: 1;
  }
}

.laptop.secondary {
  align-self: auto;

  height: 10.93495rem;
  width: 23.4114rem;

  background: url(~/src/components/bet/assets/images/mobile/secondary/button-bet-mobile-default.png)
    no-repeat;
  background-size: 100% 100%;

  &.disabled {
    background: url(~/src/components/bet/assets/images/mobile/secondary/button-bet-mobile-disabled.png)
      no-repeat;
    background-size: 100% 100%;
  }

  &.loading {
    background: url(~/src/components/bet/assets/images/mobile/secondary/button-bet-mobile-loading.png)
      no-repeat;
    background-size: 100% 100%;
  }

  &.error {
    background: url(~/src/components/bet/assets/images/mobile/secondary/button-bet-mobile-error.png)
      no-repeat;
    background-size: 100% 100%;
  }

  &:enabled:active {
    background: url(~/src/components/bet/assets/images/mobile/secondary/button-bet-mobile-active.png)
      no-repeat;
    background-size: 100% 100%;
  }

  .icon {
    @include absolute-center;

    height: 7.2025rem;
    width: 7.2025rem;

    display: block;

    animation: rotate 1000ms infinite linear;
    z-index: 1;
  }

  .info,
  .count,
  .message {
    display: none;
  }
}

.v3 {
  @include absolute-center;

  left: 2.25rem;
  top: 1.6rem;

  align-items: flex-end;
  display: flex;
  justify-content: center;

  bottom: 1.8rem;
  position: absolute;
  right: 2.125rem;
  z-index: 1;

  height: 9rem;
  width: 17.32625rem;

  overflow: hidden;

  background: url(~/src/components/bet/assets/images/v3/common/default.png) no-repeat;
  background-size: 100% 100%;

  &.basic {
    .count {
      &:first-child {
        display: none;
      }
    }
  }

  &.disabled {
    background: url(~/src/components/bet/assets/images/v3/common/disabled.png)
      no-repeat;
    background-size: 100% 100%;

    .count {
      color: #0e162d;
      letter-spacing: 0.313rem;
    }
  }

  &.loading {
    background: url(~/src/components/bet/assets/images/v3/common/loading.png)
      no-repeat;
    background-size: 100% 100%;

    .count {
      opacity: 0.2;

      &:first-child {
        display: none;
      }
    }
  }

  &.error {
    background: url(~/src/components/bet/assets/images/v3/common/error.png) no-repeat;
    background-size: 100% 100%;
  }

  &:enabled:hover {
    background: url(~/src/components/bet/assets/images/v3/common/hover.png) no-repeat;
    background-size: 100% 100%;
  }

  &:enabled:active {
    background: url(~/src/components/bet/assets/images/v3/common/active.png)
      no-repeat;
    background-size: 100% 100%;

    transform: translate3d(0, 0.2rem, 0);
  }

  .info {
    @include row;

    gap: 0.5rem;

    bottom: 1.5rem;
    left: 5.2rem;
    position: absolute;
  }

  .count {
    @include font-lato;

    font-size: 0.75rem;
    color: #b85af6;
    font-style: italic;
    font-weight: 800;
    letter-spacing: 0.45rem;
  }

  .message {
    @include row;
    @include absolute-center;
    @include font-giorgio-sans-lcg;

    align-items: center;
    justify-content: center;

    z-index: 1;

    width: 10.5rem;

    color: #e0dbd0;
    font-size: 2.125rem;
    line-height: 2.231rem;
  }

  .icon {
    @include absolute-center;

    height: 5rem;
    width: 5rem;

    display: block;

    animation: rotate 1000ms infinite linear;
    z-index: 1;
  }
}

.v3.dark {
  @include absolute-center;

  left: 2.25rem;
  top: 1.6rem;

  align-items: flex-end;
  display: flex;
  justify-content: center;

  bottom: 1.8rem;
  position: absolute;
  right: 2.125rem;
  z-index: 1;

  height: 9rem;
  width: 17.32625rem;

  overflow: hidden;

  background: url(~/src/components/bet/assets/images/v3/dark/default.png) no-repeat;
  background-size: 100% 100%;

  &.basic {
    .count {
      &:first-child {
        display: none;
      }
    }
  }

  &.disabled {
    background: url(~/src/components/bet/assets/images/v3/dark/disabled.png)
      no-repeat;
    background-size: 100% 100%;

    .count {
      color: #0e162d;
      letter-spacing: 0.313rem;
    }
  }

  &.loading {
    background: url(~/src/components/bet/assets/images/v3/dark/loading.png) no-repeat;
    background-size: 100% 100%;

    .count {
      opacity: 0.2;

      &:first-child {
        display: none;
      }
    }
  }

  &.error {
    background: url(~/src/components/bet/assets/images/v3/dark/error.png) no-repeat;
    background-size: 100% 100%;
  }

  &:enabled:hover {
    background: url(~/src/components/bet/assets/images/v3/dark/hover.png) no-repeat;
    background-size: 100% 100%;
  }

  &:enabled:active {
    background: url(~/src/components/bet/assets/images/v3/dark/active.png) no-repeat;
    background-size: 100% 100%;

    transform: translate3d(0, 0.2rem, 0);
  }

  .info {
    @include row;

    gap: 0.5rem;

    bottom: 1.5rem;
    left: 5.2rem;
    position: absolute;
  }

  .count {
    @include font-lato;

    font-size: 0.75rem;
    color: #b85af6;
    font-style: italic;
    font-weight: 800;
    letter-spacing: 0.45rem;
  }

  .message {
    @include row;
    @include absolute-center;
    @include font-giorgio-sans-lcg;

    align-items: center;
    justify-content: center;

    z-index: 1;

    width: 10.5rem;

    color: #e0dbd0;
    font-size: 2.125rem;
    line-height: 2.231rem;
  }

  .icon {
    @include absolute-center;

    height: 5rem;
    width: 5rem;

    display: block;

    animation: rotate 1000ms infinite linear;
    z-index: 1;
  }
}
