@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

@keyframes first {
  0% {
    box-shadow: none;
  }

  50% {
    box-shadow: 0 0 2rem #ffb800, inset 0 0 2rem #ffb800;
  }

  100% {
    box-shadow: none;
  }
}

@keyframes second {
  0% {
    box-shadow: none;
  }

  50% {
    box-shadow: 0 0 2rem #d9d7cf, inset 0 0 2rem #d9d7cf;
  }

  100% {
    box-shadow: none;
  }
}

@keyframes third {
  0% {
    box-shadow: none;
  }

  50% {
    box-shadow: 0 0 2rem #e77238, inset 0 0 2rem #e77238;
  }

  100% {
    box-shadow: none;
  }
}

$start: 1;
$end: 6;

.car {
  @include row;

  align-items: center;

  height: 4.188rem;
  padding-left: 0.65rem;

  color: #eceaff;
  transition: background 300ms linear, box-shadow 300ms ease-in;
  overflow: hidden;

  @for $i from $start through $end {
    &.line-#{$i} {
      background: url(~/src/widgets/cars/assets/images/progress-#{$i}.png) no-repeat;
      background-size: cover;
    }

    &.line-finished-#{$i} {
      background: url(~/src/widgets/cars/assets/images/progress-#{$i}-finished.png)
        no-repeat;
      background-size: cover;
      overflow: initial;
    }

    &.first {
      animation: first 500ms 1;
      border: 0.125rem solid #ffb800;
    }

    &.second {
      animation: second 500ms 1;
      border: 0.125rem solid #d9d7cf;
    }

    &.third {
      animation: third 500ms 1;
      border: 0.125rem solid #e77238;
    }
  }
}
