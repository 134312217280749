@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.panel {
  bottom: 0;
  position: absolute;

  height: 11.1875rem;
  width: 100%;

  background: url(~/src/widgets/panel/assets/images/v3/common/background.png)
    no-repeat;
  background-size: 100% 100%;

  &::before {
    bottom: 0;
    position: absolute;
    z-index: 1;

    height: 10.46875rem;
    width: 100%;

    background: url(~/src/widgets/panel/assets/images/v3/common/foreground.png)
      no-repeat;
    background-size: 100% 100%;
    content: "''";
  }
}

.panel.dark {
  bottom: 0;
  position: absolute;

  height: 11.1875rem;
  width: 100%;

  background: url(~/src/widgets/panel/assets/images/v3/dark/background.png) no-repeat;
  background-size: 100% 100%;

  &::before {
    bottom: 0;
    position: absolute;
    z-index: 1;

    height: 10.46875rem;
    width: 100%;

    background: url(~/src/widgets/panel/assets/images/v3/dark/foreground.png)
      no-repeat;
    background-size: 100% 100%;
    content: "''";
  }
}
