@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  @include row;

  justify-content: space-between;

  position: absolute;
  top: 9.1%;
  z-index: 1;

  height: 20%;
  padding-left: 1.938rem;
  padding-right: 2rem;
  width: 100%;

  color: #eceaff;
  text-shadow: 0 0.2rem 0.375rem rgba(0, 0, 0, 0.75);

  .border {
    display: none;
  }
}

.mobile {
  @include row;

  align-items: center;
  flex: 1 0 50%;
  gap: 4.5rem;

  position: relative;
  z-index: 1;

  height: 100%;
  padding-left: 10rem;
  width: 100%;

  color: #eceaff;
  text-shadow: 0 0.2rem 0.375rem rgba(0, 0, 0, 0.75);

  .border {
    position: absolute;

    height: 100%;
    width: 82%;
  }
}

.laptop {
  @include row;

  align-items: center;
  gap: 3.5rem;

  position: relative;
  z-index: 1;

  height: 9.0015rem;
  width: 32.18220615rem;

  color: #eceaff;
  text-shadow: 0 0.2rem 0.375rem rgba(0, 0, 0, 0.75);

  .border {
    inset: 0;
    position: absolute;

    height: 100%;
    width: 100%;
  }
}

.v3 {
  @include row;

  align-items: center;

  left: 0;
  position: relative;
  top: 0;
  z-index: 1;

  height: 4.2481rem;
  width: 15.1875rem;

  color: #eceaff;
  text-shadow: 0 0.2rem 0.375rem rgba(0, 0, 0, 0.75);

  .border {
    position: absolute;

    height: 100%;
    width: 100%;
  }
}

.v3.dark {
  color: #fefdff;
}
