@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop,
.mobile,
.laptop {
  display: none;
}

.v3 {
  bottom: 0;
  position: absolute;

  padding-top: 1.375rem;
  width: 100%;

  .jaw {
    position: absolute;
    top: 0.05rem;

    height: 1.375rem;
    width: 100%;

    background: url(~/src/widgets/markets/assets/images/common/market-jaw.png)
      no-repeat;
    background-size: 100% 100%;
  }

  .main {
    height: 100%;
    width: 100%;

    background: linear-gradient(
      180deg,
      #171d30db 0%,
      #141929 8%,
      #141929 77.5%,
      #131726 80%
    );
  }
}

.v3.dark {
  .jaw {
    background: url(~/src/widgets/markets/assets/images/v3/dark/market-jaw.png)
      no-repeat;
    background-size: 100% 100%;
  }

  .main {
    background: radial-gradient(
      110.2% 110.2% at 50.31% 110.2%,
      #1f1f33 0%,
      #151825 30.5%,
      #11131b 100%
    );
  }
}

.v3.markets {
  bottom: 0;
  position: absolute;

  padding-top: 1.375rem;
  width: 100%;

  .jaw {
    display: none;
  }

  .main {
    height: 100%;
    width: 100%;

    background: url(~/src/widgets/markets/assets/images/v3/markets/background.webp)
      no-repeat;
    background-position: 0 -4.5rem;
    background-size: 100% 100%;
  }
}
