@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.controls {
  @include row;

  justify-content: space-between;

  left: 2.775rem;
  position: absolute;
  top: 11.2rem;

  height: 4.938rem;
  width: 55rem;
}
