@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.result {
  @include row;

  align-items: center;
  gap: 0.7rem;
  justify-content: flex-start;

  > div {
    z-index: 1;
  }

  .cup {
    width: 3rem;

    filter: drop-shadow(0 0.25rem 0.25rem rgba(0, 0, 0, 0.25));
  }

  .time {
    @include row;

    align-items: baseline;
    display: none;

    &.active {
      display: block;
    }

    span {
      &:first-child {
        @include font-giorgio-sans-lcg;

        font-size: 3.375rem;
        line-height: 3.625rem;
      }

      &:last-child {
        @include font-giorgio-sans-lcg;

        font-size: 2.271rem;
        line-height: 2.271rem;
      }
    }
  }
}
