@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.controls {
  @include row;

  z-index: 2;

  gap: 0.4rem;

  bottom: 3.5rem;
  left: 9.1rem;
  position: absolute;

  height: 4.0175rem;
  width: 40.016875rem;
}
