@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.speedometer {
  left: -0.1rem;
  position: absolute;
  top: 0.1rem;

  height: 2.5125rem;
  padding: 0 0.9rem;
  width: 100%;

  background: url(~/src/components/speedometer/assets/images/v3/common/speedometer-side.png)
      no-repeat,
    url(~/src/components/speedometer/assets/images/v3/common/speedometer-default.png)
      no-repeat;
  background-size: 119.4375rem 1.25rem, 58.48rem 100%;
  background-position: 0.5rem 100%, 30.913125rem 100%;

  .progress {
    left: 30.913125rem;
    position: absolute;

    height: 100%;
    width: 58.48rem;

    background: url(~/src/components/speedometer/assets/images/v3/common/speedometer-active.png)
      no-repeat;
    background-size: 100% 2.4125rem;
    background-position: bottom;

    clip-path: polygon(0 0, 50% 0, 50% 100%, 0% 100%);
    transition: clip-path 100ms linear;

    &:last-child {
      clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
    }
  }
}

.speedometer.dark {
  .progress {
    background: url(~/src/components/speedometer/assets/images/v3/dark/speedometer-active.png)
      no-repeat;
    background-size: 100% 2.4125rem;
    background-position: bottom;
  }
}
