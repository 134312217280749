@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

@keyframes hide {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  5% {
    opacity: 1;
    transform: scale(0.9);
  }
  10% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.mobile {
  position: absolute;
  top: 18.554rem;
  z-index: 1;

  height: calc(67.5rem - 18.554rem);
  width: 100%;

  .img {
    inset: 0;
    margin: auto;
    position: absolute;
    z-index: 1;

    height: 14rem;
    width: 58rem;

    opacity: 0;
    pointer-events: none;
    transition: opacity 300ms linear;

    &.hide {
      animation: hide 5s 1 linear;
    }
  }
}

.laptop {
  position: absolute;
  top: 12.9rem;
  z-index: 1;

  height: calc(67.5rem - 12.9rem);
  width: 100%;

  .img {
    inset: 0;
    margin: auto;
    position: absolute;
    z-index: 1;

    height: 14rem;
    width: 58rem;

    opacity: 0;
    pointer-events: none;
    transition: opacity 300ms linear;

    &.hide {
      animation: hide 5s 1 linear;
    }
  }
}
