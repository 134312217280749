@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.mobile {
  overflow: hidden;
  position: relative;

  height: 0rem;
  width: 100%;

  transition: height 300ms ease-out;

  &.open {
    height: calc(67.5rem - 18.554rem);
  }

  .wrapper {
    position: absolute;
    top: 0;

    height: calc(67.5rem - 18.554rem);
    padding-left: 8.4rem;
    padding-top: 1.2rem;
  }
}

.laptop {
  overflow: hidden;
  position: relative;

  height: 0rem;
  width: 100%;

  transition: height 300ms ease-out;

  &.open {
    height: calc(67.5rem - 12.9rem);
  }

  .wrapper {
    position: absolute;
    top: 0;

    height: calc(67.5rem - 12.9rem);
    padding-left: 12.5rem;
    padding-top: 8rem;
  }
}
