@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate_back {
  to {
    transform: rotate(-360deg);
  }
}

.desktop {
  position: relative;

  height: 9.375rem;
  width: 12.5rem;

  .large {
    position: absolute;
    right: 0;

    height: 7.1875rem;
    width: 7.1875rem;

    animation: rotate 2000ms infinite linear;
  }

  .small {
    bottom: 0;
    left: 1rem;
    position: absolute;

    height: 5.5rem;
    width: 5.5rem;

    animation: rotate_back 2000ms infinite linear;
  }
}

.mobile {
  position: relative;

  height: 48.933rem;
  width: 62.046rem;

  .large {
    position: absolute;
    right: 0;

    height: 37.1rem;
    width: 37.1rem;

    animation: rotate 2000ms infinite linear;
  }

  .small {
    bottom: 0;
    left: 1rem;
    position: absolute;

    height: 28.144rem;
    width: 28.144rem;

    animation: rotate_back 2000ms infinite linear;
  }
}

.laptop {
  position: relative;

  height: 41.933rem;
  width: 55.046rem;

  .large {
    position: absolute;
    right: 0;

    height: 33.1rem;
    width: 33.1rem;

    animation: rotate 2000ms infinite linear;
  }

  .small {
    bottom: 0;
    left: 1rem;
    position: absolute;

    height: 24.144rem;
    width: 24.144rem;

    animation: rotate_back 2000ms infinite linear;
  }
}
