@use "sass:map";

@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

@mixin col {
  display: flex;
  flex-direction: column;
}

@mixin row {
  display: flex;
  flex-direction: row;
}

@mixin button-main {
  color: #eceaff;

  &:active {
    color: #7cfa4c;
  }
  &:disabled {
    color: #2f354d;
  }
  &--selected {
    color: #7cfa4c;
  }
}

@mixin button-board {
  color: #a0a7b7;

  &:active {
    color: #7cfa4c;
  }
  &:disabled {
    color: #2f354d;
  }
  &--selected {
    color: #7cfa4c;
  }
}

@mixin absolute-center {
  border: none;
  bottom: 0%;
  left: 0%;
  margin: auto;
  outline: none;
  position: absolute;
  right: 0%;
  text-align: center;
  top: 0%;
}

@mixin market-title {
  @include font-lato;

  color: #798bb5;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.14rem;
  line-height: 1.3rem;
  text-align: center;
}

@mixin line {
  color: #ffffff;

  &--even {
    color: #000000;
  }
}

@mixin lines {
  @for $i from 1 through 6 {
    &--lines {
      &-#{$i} {
        background: map.get($colors, $i);

        @for $j from 1 through 6 {
          &-#{$j} {
            background: linear-gradient(
              95deg,
              map.get($colors, $i) 0%,
              map.get($colors, $i) 50%,
              map.get($colors, $j) 50%
            );

            @for $k from 1 through 6 {
              &-#{$k} {
                background: linear-gradient(
                  to right,
                  map.get($colors, $i) 0%,
                  map.get($colors, $i) 33%,
                  map.get($colors, $j) 33%,
                  map.get($colors, $j) 66%,
                  map.get($colors, $k) 66%
                );
              }
            }
          }
        }
      }
    }
  }
}
