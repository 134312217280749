@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  display: none;
}

.mobile,
.laptop {
  @include row;

  width: 100%;

  transition: transform 200ms linear;
  touch-action: pan-x;
}
