@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

@keyframes money {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.1);
  }
}

@keyframes cars {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(0.9);
  }
}

.desktop {
  position: relative;

  height: 19.875rem;
  width: 37.5rem;

  .cars {
    bottom: 10%;
    left: -1.4rem;
    position: absolute;

    height: 14.106rem;
    width: 17.31rem;

    animation: cars 1s ease-out 0s 1;
    background: url(~/src/assets/images/desktop/cars.png) no-repeat;
    background-size: contain;
    transform: scale(0.9);
    transform-origin: left top;
  }

  .money {
    bottom: 10%;
    position: absolute;
    right: 0;

    height: 19.044rem;
    width: 27.707rem;

    animation: money 1s ease-out 0s 1;
    background: url(~/src/assets/images/desktop/money.png) no-repeat;
    background-size: contain;
    transform: scale(1.1);
    transform-origin: right bottom;
  }

  .road {
    position: absolute;
    right: 0;

    height: 19.875rem;
    width: 37.5rem;

    background: url(~/src/assets/images/desktop/road.png) no-repeat;
    background-size: contain;
  }
}

.mobile,
.laptop {
  position: relative;

  height: 70.042rem;
  width: 110.98rem;

  .cars {
    bottom: -5%;
    left: -16rem;
    position: absolute;

    height: 61.106rem;
    width: 70.31rem;

    animation: cars 1s ease-out 0s 1;
    background: url(~/src/assets/images/desktop/cars.png) no-repeat;
    background-size: contain;
    transform: scale(0.9);
    transform-origin: left top;
  }

  .money {
    bottom: 15%;
    position: absolute;
    right: -13rem;

    height: 60.044rem;
    width: 90.707rem;

    animation: money 1s ease-out 0s 1;
    background: url(~/src/assets/images/desktop/money.png) no-repeat;
    background-size: contain;
    transform: scale(1.1);
    transform-origin: right bottom;
  }

  .road {
    height: 70.042rem;
    width: 110.98rem;

    background: url(~/src/assets/images/mobile/road.png) no-repeat;
    background-size: contain;
  }
}

.v3 {
  position: relative;

  height: 22.65875rem;
  width: 56.6875rem;

  .cars {
    bottom: 10%;
    left: -1rem;
    top: 4.5rem;
    position: absolute;

    height: 21.106rem;
    width: 24.31rem;

    animation: cars 1s ease-out 0s 1;
    background: url(~/src/assets/images/v3/cars.png) no-repeat;
    background-size: contain;
    transform: scale(0.9);
    transform-origin: left top;
  }

  .money {
    bottom: 10%;
    position: absolute;
    right: 0;
    top: -1rem;

    height: 25.044rem;
    width: 33.707rem;

    animation: money 1s ease-out 0s 1;
    background: url(~/src/assets/images/v3/money.png) no-repeat;
    background-size: contain;
    transform: scale(1.1);
    transform-origin: right bottom;
  }

  .road {
    position: absolute;
    right: 0;

    height: 24.080625rem;
    width: 56.6875rem;

    background: url(~/src/assets/images/v3/road.png) no-repeat;
    background-size: contain;
  }
}
