@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

@keyframes signal {
  0% {
    color: #eceaff;
  }
  50% {
    color: #6c7392;
  }
  100% {
    color: #eceaff;
  }
}

.ready {
  animation: signal 1000ms infinite linear;
}

.steady {
  animation: signal 700ms infinite linear;
}

.desktop,
.mobile,
.laptop,
.v3 {
  @include row;
  @include font-giorgio-sans-lcg;

  align-items: flex-end;
  justify-content: flex-end;

  transition: color 100ms linear;
}

.desktop {
  height: 105%;

  .sc {
    font-size: 6.375rem;
    letter-spacing: 0.063rem;
    line-height: 5.25rem;
  }

  .ms {
    font-size: 2.438rem;
    letter-spacing: 0.094rem;
    line-height: 2.225rem;
  }
}

.mobile {
  gap: 1rem;

  .sc {
    font-size: 12rem;
    letter-spacing: 0.05rem;
    line-height: 20rem;
  }

  .ms {
    font-size: 4.5rem;
    letter-spacing: 0.1rem;
    line-height: 14.5rem;
  }
}

.laptop {
  gap: 0.45rem;

  .sc {
    font-size: 8.55rem;
    letter-spacing: 0.05rem;
    line-height: 10.55rem;
  }

  .ms {
    font-size: 3.15rem;
    letter-spacing: 0.1rem;
    line-height: 6.8rem;
  }
}

.v3 {
  gap: 0.05rem;

  padding-left: 1.5rem;

  .sc {
    font-size: 4.0625rem;
    letter-spacing: 0.05rem;
    line-height: 20rem;
  }

  .ms {
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
    line-height: 18rem;
  }
}
