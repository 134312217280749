@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  align-items: center;
  display: flex;
  justify-content: center;

  height: 100%;

  &.result {
    padding: 0 0.9375rem 0 1.3125rem;
  }

  &.competition {
    padding: 0 0.9375rem 0 1.3125rem;
  }

  &.sum {
    padding: 0 7.25rem 0 7.25rem;
  }

  &.tops {
    padding: 0 4.875rem;
  }
}

.mobile,
.laptop {
  display: flex;

  height: 100%;
}

.v3 {
  display: flex;

  height: 100%;
}
