@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.market {
  @include col;

  position: absolute;
  top: calc(0.14637 * (100vh - 17.083rem));

  padding-top: 0.701rem;
  height: calc(0.7797 * (100vh - 17.083rem));
  width: 95.5%;

  .body {
    display: grid;
    flex: 1;
    grid-column-gap: 1.088rem;
    grid-row-gap: 4.1%;
    grid-template-columns: 3.011rem 1fr;
    grid-template-rows: 8% 1fr;

    padding-right: 0.36375rem;
  }

  .cups {
    @include row;

    justify-content: space-around;

    > img {
      flex: 0 0 6.4375rem;

      &:nth-of-type(7) {
        margin-left: 0.5rem;
      }
    }
  }

  .lines {
    align-items: center;
    gap: 0.25rem;
    justify-content: space-around;

    &:nth-child(2) {
      @include row;
    }

    &:nth-child(3) {
      @include col;
    }
  }

  .data {
    @include row;

    gap: 0.75rem;
  }

  .bets {
    display: grid;
    grid-column-gap: 0.25rem;
    grid-row-gap: 0.25rem;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);

    width: 39.475rem;

    &:last-child {
      grid-template-columns: repeat(2, 1fr);
      width: 13.125rem;
    }
  }
}
