@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.mobile {
  @include col;

  flex: 1 0 auto;

  padding: 0 4.476rem;
  padding-bottom: 50rem;
  width: 100%;

  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  .title {
    @include market-title;
  }

  .header {
    @include row;

    gap: 3rem;

    position: sticky;
    top: 0;
    z-index: 1;

    background: rgba(21, 26, 43, 1);

    > div {
      flex: 1 0 50%;
    }
  }

  .body {
    display: grid;
    grid-column-gap: 6.074rem;
    grid-row-gap: 6.394rem;
    grid-template-columns: repeat(2, 1fr);
  }

  .cups {
    @include row;

    align-items: center;
    gap: 0.959rem;

    height: 14.788rem;
    width: 19.502rem;
  }

  .bets {
    @include col;

    gap: 0.479743625rem;
  }

  .bet {
    @include row;

    align-items: center;

    height: 15.351rem;

    gap: 2.238rem;
  }

  .lines {
    @include row;

    gap: 0.959rem;

    width: 19.502rem;
  }
}

.laptop {
  @include col;

  flex: 1 0 auto;

  padding: 0 15.5rem;
  padding-bottom: 50rem;
  width: 100%;

  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  .title {
    @include market-title;
  }

  .header {
    @include row;

    gap: 3rem;

    position: sticky;
    top: 0;
    z-index: 1;

    background: rgba(21, 26, 43, 1);

    > div {
      flex: 1 0 50%;
    }
  }

  .body {
    display: grid;
    grid-column-gap: 6.074rem;
    grid-row-gap: 5.8064rem;
    grid-template-columns: repeat(2, 1fr);
  }

  .cups {
    @include row;

    align-items: center;
    gap: 0.959rem;

    height: 7.788rem;
    width: 19.502rem;

    img {
      width: 6.575rem;
    }
  }

  .bets {
    @include col;

    gap: 0.3225rem;
  }

  .bet {
    @include row;

    align-items: center;

    height: 11.2905rem;

    gap: 2.238rem;
  }

  .lines {
    @include row;

    gap: 0.959rem;

    width: 13.502rem;

    div {
      width: 6.5rem;

      font-size: 4.275rem;
    }
  }
}
