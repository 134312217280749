@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop,
.v3 {
  @include absolute-center;
  @include row;

  justify-content: flex-end;

  height: 27.313rem;
  width: 51.25rem;

  background: url(~/src/assets/images/desktop/abort.png) no-repeat;
  background-size: contain;
  color: #ffffff;

  .content {
    @include col;

    align-self: center;
    flex: 0 0 17.938rem;
    gap: 2rem;

    padding: 0 2.5rem;
  }

  .title {
    @include font-giorgio-sans-lcg;

    font-size: 3.375rem;
    letter-spacing: 0.063rem;
    line-height: 2.625rem;
  }

  .description {
    @include font-lato;

    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.013rem;
  }
}

.mobile,
.laptop {
  @include absolute-center;
  @include col;

  justify-content: flex-end;

  height: 118.976rem;
  width: 110.948rem;

  background: url(~/src/assets/images/mobile/abort.png) no-repeat;
  background-size: contain;
  color: #ffffff;

  .content {
    @include col;

    align-self: center;
    flex: 0 0 30rem;
    gap: 2.558rem;
    justify-content: center;
  }

  .title {
    @include font-giorgio-sans-lcg;

    font-size: 11.513rem;
    letter-spacing: 0.063rem;
    line-height: 8.513rem;
  }

  .description {
    @include font-lato;

    font-size: 3.332rem;
    font-weight: 600;
    letter-spacing: 0.013rem;
    line-height: 3.332rem;
  }
}
