@mixin button {
  position: absolute;
  top: 45%;
  z-index: 1;

  height: 4.688rem;
  width: 4.688rem;
}

@mixin active {
  &:active {
    filter: brightness(0.5);
  }
}

.desktop,
.v3 {
  left: 0;
  position: absolute;
  top: 0;
  z-index: 9999;

  height: 100%;
  width: 100%;

  background: #171d2b;
}

.desktop {
  @for $i from 1 through 6 {
    .slide-#{$i} {
      height: 100vh;

      background: url(~/src/widgets/tutorial/assets/images/desktop/tutorial-#{$i}.png)
        no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}

.v3 {
  @for $i from 1 through 6 {
    .slide-#{$i} {
      height: 100vh;

      background: url(~/src/widgets/tutorial/assets/images/v3/default/tutorial-#{$i}.png)
        no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}

.v3.markets {
  @for $i from 1 through 6 {
    .slide-#{$i} {
      height: 100vh;

      background: url(~/src/widgets/tutorial/assets/images/v3/markets/tutorial-#{$i}.png)
        no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}

.next {
  @include button;
  @include active;

  right: 1rem;

  background: url(~/src/widgets/tutorial/assets/images/common/next.png) no-repeat;
  background-size: 100% 100%;
}

.prev {
  @include button;
  @include active;

  left: 1rem;

  background: url(~/src/widgets/tutorial/assets/images/common/prev.png) no-repeat;
  background-size: 100% 100%;
}

.close {
  @include active;

  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 5;

  height: 4rem;
  width: 4rem;

  background: url(~/src/widgets/tutorial/assets/images/common/close.png) no-repeat;
  background-size: 100% 100%;
}
