@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  display: none;
}

.mobile {
  @include col;

  align-items: center;
  gap: 3.837rem;
  justify-content: center;

  z-index: 5;

  padding-bottom: 5.117rem;
  padding-top: 4.477rem;

  .hide {
    @include font-giorgio-sans-lcg;

    height: 17.91rem;
    width: 104.584rem;

    background: url(~/src/assets/images/mobile/hide-default.png);
    background-size: 100% 100%;
    color: #9e4aef;
    font-size: 7.675rem;

    &:active {
      background: url(~/src/assets/images/mobile/hide-active.png);
      background-size: 100% 100%;
      color: #bf38ff;
    }
  }

  .wrapper {
    height: 17.904rem;

    overflow: hidden;
    transition: height 300ms linear;

    &.hidden {
      height: 0;
    }
  }
}

.laptop {
  @include row;

  align-items: center;
  gap: 2.0965rem;
  justify-content: center;

  z-index: 5;

  padding: 0 12.58062rem;
  padding-bottom: 3.87095rem;
  padding-top: 3.54835rem;

  .hide {
    @include font-giorgio-sans-lcg;

    height: 9.1225rem;
    width: 100%;

    background: url(~/src/assets/images/mobile/hide-default.png);
    background-size: 100% 100%;
    color: #9e4aef;
    font-size: 4.429025rem;

    &:active {
      background: url(~/src/assets/images/mobile/hide-active.png);
      background-size: 100% 100%;
      color: #bf38ff;
    }
  }

  .wrapper {
    height: 9.1225rem;
    width: 100%;

    overflow: hidden;
    transition: width 300ms linear;

    &.hidden {
      width: 0;
    }

    button {
      white-space: nowrap;
    }
  }
}
