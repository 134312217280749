@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.button {
  @include font-giorgio-sans-lcg;

  color: #a0a7b7;

  &.selected {
    color: #7cfa4c;
  }

  &:active:not(.selected) {
    color: #7cfa4c;
  }
  &:disabled:not(.selected) {
    color: #2f354d;
  }
}

.desktop {
  @include row;

  gap: 1rem;
  justify-content: space-between;

  left: 3.375rem;
  position: absolute;
  top: 5.25rem;
  z-index: 1;

  height: 4.938rem;
  width: 55.025rem;

  .button {
    height: 4.938rem;
    width: 13.516rem;

    background: url(~/src/components/amounts/assets/images/desktop/default.png)
      no-repeat;
    background-size: 100% 100%;
    font-size: 2.625rem;
    line-height: 3.063rem;

    &.selected {
      background: url(~/src/components/amounts/assets/images/desktop/selected.png)
        no-repeat;
      background-size: 100% 100%;
    }

    &:hover:not(.selected) {
      background: url(~/src/components/amounts/assets/images/desktop/hover.png)
        no-repeat;
      background-size: 100% 100%;
    }
    &:active:not(.selected) {
      background: url(~/src/components/amounts/assets/images/desktop/active.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }
}

.mobile {
  @include row;

  align-items: flex-start;
  justify-content: space-between;

  height: 15.346rem;
  padding-left: 4.476rem;
  padding-right: 3.836rem;
  padding-top: 1.278rem;
  width: 100%;

  background: linear-gradient(180deg, #151a2a 0%, #151a2b 100%);
  transition: height 1s linear;

  .button {
    height: 15.026rem;
    width: 26.216rem;

    background: url(~/src/components/amounts/assets/images/mobile/default.png)
      no-repeat;
    background-size: 100% 100%;
    font-size: 8.459rem;
    line-height: 3.063rem;

    &.selected {
      background: url(~/src/components/amounts/assets/images/mobile/selected.png)
        no-repeat;
      background-size: 100% 100%;
    }

    &:active:not(.selected) {
      background: url(~/src/components/amounts/assets/images/mobile/active.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }
}

.laptop {
  @include row;

  align-items: flex-start;
  justify-content: space-between;

  height: 10.15rem;
  padding-left: 4.476rem;
  padding-right: 3.836rem;
  padding-top: 1.278rem;
  width: 100%;

  background: linear-gradient(180deg, #151a2a 0%, #151a2b 100%);
  transition: height 1s linear;

  .button {
    height: 11.026rem;
    width: 26.216rem;

    background: url(~/src/components/amounts/assets/images/mobile/default.png)
      no-repeat;
    background-size: 100% 100%;
    font-size: 6.7435rem;
    line-height: 3.063rem;

    &.selected {
      background: url(~/src/components/amounts/assets/images/mobile/selected.png)
        no-repeat;
      background-size: 100% 100%;
    }

    &:active:not(.selected) {
      background: url(~/src/components/amounts/assets/images/mobile/active.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }
}

.v3 {
  @include row;

  gap: 0.2rem;
  justify-content: space-between;

  bottom: 3.5rem;
  position: absolute;
  right: 3.15rem;
  z-index: 1;

  height: 3.978125rem;
  width: 29.795625rem;

  .button {
    align-items: baseline;
    display: flex;
    justify-content: center;

    height: 100%;
    width: 100%;

    background: url(~/src/components/amounts/assets/images/v3/common/default.png)
      no-repeat;
    background-size: 100% 100%;
    font-size: 2.125rem;
    line-height: 3.625rem;
    font-style: normal;

    &.selected {
      background: url(~/src/components/amounts/assets/images/v3/common/selected.png)
        no-repeat;
      background-size: 100% 100%;
    }

    &:hover:not(.selected) {
      background: url(~/src/components/amounts/assets/images/v3/common/hover.png)
        no-repeat;
      background-size: 100% 100%;
    }
    &:active:not(.selected) {
      background: url(~/src/components/amounts/assets/images/v3/common/active.png)
        no-repeat;
      background-size: 100% 100%;
    }

    span {
      display: inline-block;

      transform: skewX(5deg);
    }
  }
}

.v3.dark {
  .button {
    background: url(~/src/components/amounts/assets/images/v3/dark/default.png)
      no-repeat;
    background-size: 100% 100%;
    color: #a0a7b7;

    &.selected {
      background: url(~/src/components/amounts/assets/images/v3/dark/selected.png)
        no-repeat;
      background-size: 100% 100%;
      color: #fefdff;
    }

    &:hover:not(.selected) {
      background: url(~/src/components/amounts/assets/images/v3/dark/hover.png)
        no-repeat;
      background-size: 100% 100%;
      color: #a0a7b7;
    }
    &:active:not(.selected) {
      background: url(~/src/components/amounts/assets/images/v3/dark/active.png)
        no-repeat;
      background-size: 100% 100%;
      color: #6f98ff;
    }
  }
}
