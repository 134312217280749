@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop,
.mobile,
.laptop {
  display: none;
}

.v3 {
  position: relative;

  height: 33.75rem;
  width: 60rem;

  .info {
    @include row;

    align-items: center;
    justify-content: space-between;

    position: absolute;
    top: 0;

    height: 6.125rem;
    padding: 0 2rem;
    width: 60rem;

    background: rgba(19, 26, 43, 0.8);
  }
}
