@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.popup {
  @include row;

  align-items: center;
  gap: 5.117265rem;
  justify-content: center;

  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: -24.5rem;
  z-index: -1;

  height: 20.46905rem;
  width: 109.701375rem;

  backdrop-filter: blur(1rem);
  background: rgba(21, 26, 43, 0.7);
  border: 0.31985rem solid rgba(255, 255, 255, 0.25);
  border-radius: 3.83795rem;
  transform: translate3d(0, 100rem, 0);
  transition: transform 100ms ease-in-out;

  &.show {
    transform: translate3d(0, 0rem, 0);
  }

  span {
    @include font-giorgio-sans-lcg;

    color: #eceaff;
    font-size: 8.955214rem;
    letter-spacing: 0.04em;
  }
}

.icon {
  @include row;

  align-items: center;
  justify-content: center;

  height: 10.2345rem;
  width: 10.2345rem;

  background: #1f2647;
  border-radius: 50%;
}

.wallet {
  width: 4.9765rem;
}
