@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.market {
  @include col;

  flex: 1;

  position: absolute;
  top: calc(0.14637 * (100vh - 17.083rem));

  padding-top: 0.701rem;
  height: calc(0.7797 * (100vh - 17.083rem));
  width: 83.5%;

  .body {
    display: grid;
    flex: 1;
    grid-column-gap: 1.088rem;
    grid-row-gap: 4.1%;
    grid-template-columns: 3.011rem 1fr;
    grid-template-rows: 8% 1fr;
  }

  .cups {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 1.25rem;

    > img {
      width: 100%;
    }
  }

  .lines {
    @include col;

    gap: 1.25rem;
    justify-content: space-around;

    width: 4.438rem;
  }

  .bets {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 1.25rem;
    grid-row-gap: 1.25rem;

    width: 100%;
  }
}
