@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  @include col;

  height: 100%;
  padding: 0 2rem;
  padding-top: calc(calc(24.76vh - 10.563rem) / 2);

  background: linear-gradient(180deg, #252e46 0%, #2b344e 100%);

  .body {
    @include col;
  }

  .footer {
    @include row;

    align-items: center;

    padding-left: 4.6rem;

    span {
      @include font-lato;

      color: #a1b1d9;
      font-size: 0.875rem;
      font-style: italic;
      font-weight: 800;
      letter-spacing: 0.575rem;
      line-height: 2.8rem;
      text-align: center;
    }
  }

  .live {
    display: none;
  }

  .statistic {
    @include row;

    align-items: center;
    justify-content: space-between;
  }

  .cup {
    filter: drop-shadow(0 0.25rem 0.25rem rgba(0, 0, 0, 0.25));

    height: 2.25rem;
    width: 2.5625rem;
  }

  .lines {
    @include row;
  }

  .line {
    @include font-giorgio-sans-lcg;

    align-items: center;
    display: flex;
    height: 47px;
    justify-content: center;

    height: 2.9375rem;
    width: 2.1575rem;

    font-size: 2rem;
    line-height: 1.596rem;
  }
}

.mobile {
  @include col;

  background: none;

  .body {
    @include col;
  }

  .footer {
    @include row;

    align-items: center;

    padding-left: 28.5rem;

    span {
      @include font-lato;

      color: #a1b1d9;
      font-size: 3.605rem;
      font-style: italic;
      font-weight: 800;
      letter-spacing: 1.25rem;
      line-height: 8rem;
      text-align: center;
    }
  }

  .live {
    display: none;
  }

  .statistic {
    @include row;

    align-items: baseline;
    gap: 5.25rem;
    justify-content: space-between;
  }

  .cup {
    filter: drop-shadow(0 0.25rem 0.25rem rgba(0, 0, 0, 0.25));

    height: 7.7205rem;
    width: 9.1315rem;
  }

  .lines {
    @include row;
  }

  .line {
    @include font-giorgio-sans-lcg;

    align-items: center;
    display: flex;
    justify-content: center;

    height: 12.2rem;
    width: 8.9rem;

    font-size: 8.8rem;
    line-height: 1.596rem;
  }
}

.laptop {
  @include col;

  background: none;

  .body {
    @include col;
  }

  .footer {
    @include row;

    align-items: center;

    padding-left: 26.5rem;

    span {
      @include font-lato;

      color: #a1b1d9;
      font-size: 3.3035rem;
      font-style: italic;
      font-weight: 800;
      letter-spacing: 1.105rem;
      line-height: 8rem;
      text-align: center;
    }
  }

  .live {
    display: none;
  }

  .statistic {
    @include row;

    align-items: flex-start;
    gap: 4.25rem;
    justify-content: space-between;
  }

  .cup {
    filter: drop-shadow(0 0.25rem 0.25rem rgba(0, 0, 0, 0.25));

    height: 9rem;
    padding-top: 0.85rem;
    width: 9rem;
  }

  .lines {
    @include row;
  }

  .line {
    @include font-giorgio-sans-lcg;

    align-items: center;
    display: flex;
    justify-content: center;

    height: 11rem;
    width: 8.165rem;

    font-size: 6.775rem;
    line-height: 1.596rem;
  }
}

.v3 {
  @include row;

  flex-direction: row-reverse;
  justify-content: space-between;

  position: absolute;
  right: -1.2rem;
  top: 0;
  z-index: 1;

  height: 8.375rem;
  padding-left: 1.8625rem;
  padding-right: 3.9125rem;
  padding-top: 1rem;
  width: 34.875rem;

  background: #202749;
  transform: skewX(-5deg);

  .body {
    @include col;
  }

  .footer {
    padding-left: 0.25rem;
    padding-top: 0.7rem;
    width: 6rem;

    transform: skew(5deg);

    span {
      @include font-giorgio-sans-lcg;

      color: #e5e3fd;
      font-size: 2.125rem;
      letter-spacing: 0.01rem;
      line-height: 1.7rem;
    }

    &.monitor {
      padding: 0;

      span {
        letter-spacing: -0.04rem;
        line-height: 1.5rem;
      }
    }
  }

  .live {
    @include row;

    gap: 0.1875rem;

    left: 1.1rem;
    position: absolute;
    top: 4.95rem;

    height: 1.7rem;
    width: 10rem;

    background: url(~/src/components/statistics/assets/images/v3/button-live-default.png)
      no-repeat;
    background-size: 100% 100%;
    transform: skew(5deg);
    visibility: hidden;

    &:hover {
      background: url(~/src/components/statistics/assets/images/v3/button-live-hover.png)
        no-repeat;
      background-size: 100% 100%;
    }
    &:active {
      background: url(~/src/components/statistics/assets/images/v3/button-live-active.png)
        no-repeat;
      background-size: 100% 100%;
    }

    &.visible {
      visibility: visible;
    }
  }

  .statistic {
    @include row;

    align-items: center;
    gap: 0.4rem;
    justify-content: space-between;

    &:first-child {
      span {
        &:first-child {
          border-radius: 15% 0% 0% 0%;
        }

        &:last-child {
          border-radius: 0% 15% 0% 0%;
        }
      }
    }

    &:last-child {
      span {
        &:first-child {
          border-radius: 0% 0% 0% 15%;
        }

        &:last-child {
          border-radius: 0% 0% 15% 0%;
        }
      }
    }
  }

  .cup {
    filter: drop-shadow(0 0.25rem 0.25rem rgba(0, 0, 0, 0.25));

    height: 1.374375rem;
    width: 1.625rem;
  }

  .lines {
    @include row;
  }

  .line {
    @include font-giorgio-sans-lcg;

    align-items: center;
    display: flex;
    justify-content: center;

    height: 1.6875rem;
    width: 1.6875rem;

    font-size: 1.5rem;
  }
}

.v3.markets {
  @include row;

  flex-direction: row-reverse;
  justify-content: space-between;

  position: absolute;
  right: -1.2rem;
  top: 0;
  z-index: 1;

  height: 8.375rem;
  padding-left: 1.8625rem;
  padding-right: 3.9125rem;
  padding-top: 1.5rem;
  width: 34.875rem;

  background: #202749;
  transform: skewX(-5deg);

  .body {
    @include col;
  }

  .footer {
    padding-left: 0.25rem;
    padding-top: 0.7rem;
    width: 6rem;

    transform: skew(5deg);

    span {
      @include font-giorgio-sans-lcg;

      color: #e5e3fd;
      font-size: 2.125rem;
      letter-spacing: 0.01rem;
      line-height: 1.7rem;
    }

    &.monitor {
      padding: 0;

      span {
        letter-spacing: -0.04rem;
        line-height: 1.5rem;
      }
    }
  }

  .live {
    @include row;

    gap: 0.1875rem;

    left: 1.1rem;
    position: absolute;
    top: 5.45rem;

    height: 1.7rem;
    width: 10rem;

    background: url(~/src/components/statistics/assets/images/v3/button-live-default.png)
      no-repeat;
    background-size: 100% 100%;
    transform: skew(5deg);
    visibility: hidden;

    &:hover {
      background: url(~/src/components/statistics/assets/images/v3/button-live-hover.png)
        no-repeat;
      background-size: 100% 100%;
    }
    &:active {
      background: url(~/src/components/statistics/assets/images/v3/button-live-active.png)
        no-repeat;
      background-size: 100% 100%;
    }

    &.visible {
      visibility: visible;
    }
  }

  .statistic {
    @include row;

    align-items: center;
    gap: 0.4rem;
    justify-content: space-between;

    &:first-child {
      span {
        &:first-child {
          border-radius: 15% 0% 0% 0%;
        }

        &:last-child {
          border-radius: 0% 15% 0% 0%;
        }
      }
    }

    &:last-child {
      span {
        &:first-child {
          border-radius: 0% 0% 0% 15%;
        }

        &:last-child {
          border-radius: 0% 0% 15% 0%;
        }
      }
    }
  }

  .cup {
    filter: drop-shadow(0 0.25rem 0.25rem rgba(0, 0, 0, 0.25));

    height: 1.374375rem;
    width: 1.625rem;
  }

  .lines {
    @include row;
  }

  .line {
    @include font-giorgio-sans-lcg;

    align-items: center;
    display: flex;
    justify-content: center;

    height: 1.6875rem;
    width: 1.6875rem;

    font-size: 1.5rem;
  }
}
