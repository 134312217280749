@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

$markets: (
  "result": left,
  "tops": left,
  "competition": center,
  "sum": right,
  "three-from-six": right,
);

.desktop {
  @include row;

  justify-content: center;

  height: 5.125rem;
  padding: 0 1.25rem;

  .button {
    @include font-giorgio-sans-lcg;

    height: 5.125rem;
    width: 19.063rem;

    color: #eceaff;
    font-size: 1.75rem;
    letter-spacing: 0.04em;
    line-height: 2.625rem;

    @each $market, $position in $markets {
      &.#{$market} {
        background: url(~/src/widgets/markets/assets/images/common/button-#{$position}-default.png)
          0%
          0% /
          contain
          no-repeat;

        &:not(:last-child) {
          border-right: 0.063rem solid rgba(3, 5, 9, 1);
        }

        &.selected {
          background: url(~/src/widgets/markets/assets/images/common/button-#{$position}-selected.png)
            0%
            0% /
            contain
            no-repeat !important;
          color: #7cfa4c;
        }
        &:hover {
          background: url(~/src/widgets/markets/assets/images/common/button-#{$position}-hover.png)
            0%
            0% /
            contain
            no-repeat;
        }
        &:active {
          background: url(~/src/widgets/markets/assets/images/common/button-#{$position}-active.png)
            0%
            0% /
            contain
            no-repeat;
          color: #7cfa4c;
        }
      }
    }
  }
}

.mobile {
  @include row;

  justify-content: center;

  position: absolute;
  top: -1rem;
  z-index: 2;

  padding: 0 4.5rem;

  height: 11.833rem;
  width: 143.328rem;

  transition: transform 300ms linear;

  &.competition {
    transform: translate3d(-11.25rem, 0, 0);
  }

  &.sum {
    transform: translate3d(-23.5rem, 0, 0);
  }

  .button {
    @include font-giorgio-sans-lcg;

    flex: 1;

    color: #eceaff;
    font-size: 5.435rem;
    letter-spacing: 0.04em;
    line-height: 2.625rem;

    @each $market, $position in $markets {
      &.#{$market} {
        background: url(~/src/widgets/markets/assets/images/mobile/button-#{$position}-default.png);
        background-size: 100% 100%;

        &.selected {
          background: url(~/src/widgets/markets/assets/images/mobile/button-#{$position}-selected.png);
          background-size: 100% 100%;
          color: #7cfa4c;
        }

        &:active {
          background: url(~/src/widgets/markets/assets/images/mobile/button-#{$position}-selected.png);
          background-size: 100% 100%;
          color: #7cfa4c;
        }
      }
    }
  }
}

.laptop {
  @include row;

  justify-content: center;

  left: 12.580624rem;
  position: absolute;
  top: -0.75rem;
  z-index: 2;

  height: 8.3275rem;

  .button {
    @include font-giorgio-sans-lcg;

    flex: 1;

    width: 31.5065rem;

    color: #eceaff;
    font-size: 3.825rem;
    letter-spacing: 0.04em;
    line-height: 2.625rem;

    @each $market, $position in $markets {
      &.#{$market} {
        background: url(~/src/widgets/markets/assets/images/mobile/button-#{$position}-default.png);
        background-size: 100% 100%;

        &.selected {
          background: url(~/src/widgets/markets/assets/images/mobile/button-#{$position}-selected.png);
          background-size: 100% 100%;
          color: #7cfa4c;
        }

        &:active {
          background: url(~/src/widgets/markets/assets/images/mobile/button-#{$position}-selected.png);
          background-size: 100% 100%;
          color: #7cfa4c;
        }
      }
    }
  }
}
