@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

$markets: (
  "result": left,
  "tops": left,
  "competition": center,
  "sum": right,
  "3-from-6": right,
);

.desktop {
  @include col;

  position: relative;
  z-index: 2;

  height: calc(100vh - 17.083rem);
  width: 60rem;

  background: url(~/src/widgets/markets/assets/images/desktop/market-left-bottom.png)
      no-repeat,
    url(~/src/widgets/markets/assets/images/desktop/market-right-bottom.png)
      no-repeat;
  background-position: bottom left, bottom right;
  background-size: 3.5%, 3.5%;

  &::after {
    content: "";

    inset: 0;
    position: absolute;
    z-index: -1;

    margin: auto;
    width: 94%;

    background: linear-gradient(
      180deg,
      #1c223a 0.15%,
      #171e36 0.7%,
      #192035 1.43%,
      #161c2e 7.51%,
      #161c31 20.87%,
      #151a2f 46.19%,
      #171d31 100.15%
    );
  }

  &:before {
    content: "";

    inset: 0;
    position: absolute;
    z-index: -1;

    height: calc(100vh - 19.8rem);
    width: 100%;

    background: linear-gradient(
      180deg,
      #1c223a 0.15%,
      #171e36 0.7%,
      #192035 1.43%,
      #161c2e 7.51%,
      #161c31 20.87%,
      #151a2f 46.19%,
      #171d31 100.15%
    );
    box-shadow: 0.063rem 0.063rem 0 0 rgba(55, 65, 92, 1) inset;
  }
}

.mobile {
  @include col;

  flex: 1;

  position: relative;

  min-height: 0;
  padding-top: 10rem;
  width: 100%;

  background: rgba(21, 26, 43, 1);
  background: linear-gradient(
    to bottom,
    rgba(255, 0, 0, 0) 0%,
    rgba(255, 0, 0, 0) 50%,
    rgba(21, 26, 43, 1) 50%
  );
  box-shadow: 0 0.374rem 0 0 rgba(55, 65, 92, 1) inset;
}

.laptop {
  @include col;

  flex: 1;

  position: relative;

  min-height: 0;
  padding-top: 9.8rem;
  width: 100%;

  background: rgba(21, 26, 43, 1);
  background: linear-gradient(
    to bottom,
    rgba(255, 0, 0, 0) 0%,
    rgba(255, 0, 0, 0) 50%,
    rgba(21, 26, 43, 1) 50%
  );
  box-shadow: 0 0.374rem 0 0 rgba(55, 65, 92, 1) inset;
}

.v3 {
  bottom: 0;
  position: absolute;

  width: 100%;

  clip-path: polygon(5rem 0, 100% 0, 100% 100%, 0 100%, 0 1.25rem, 4rem 1.25rem);

  .titles {
    display: none;
  }

  .rows {
    position: absolute;
    top: 0.25%;

    display: grid;
    grid-template-areas:
      "personal tops competition"
      "sums sums sums";
    grid-template-columns: 49.85rem 15.75rem 49.85rem;
    grid-template-rows: 54.5% 21.5%;
    justify-content: space-between;

    height: 100%;
    padding-left: 1.4375rem;
    padding-right: 1.4375rem;
    width: 100%;

    > div {
      &:nth-of-type(1) {
        grid-area: personal;
      }

      &:nth-of-type(2) {
        grid-area: tops;
      }

      &:nth-of-type(3) {
        grid-area: competition;
      }

      &:nth-of-type(4) {
        grid-area: sums;
      }
    }
  }
}

.v3.markets {
  bottom: 0;
  position: absolute;

  width: 100%;

  clip-path: polygon(0% 1.4rem, 100% 1.4rem, 100% 100%, 0% 100%);

  .titles {
    @include row;

    align-items: center;
    gap: 1.5rem;
    justify-content: space-around;

    position: absolute;
    top: 6.5%;

    padding: 0 5.5rem;
    width: 100%;

    span {
      @include font-giorgio-sans-lcg;

      color: #8c92d1;
      font-size: 1.625rem;
      letter-spacing: 0.12rem;
    }
  }

  .rows {
    position: absolute;
    top: 10.85%;

    display: grid;
    grid-template-areas: "competition personal";
    grid-template-columns: 56.84375rem 56.84375rem;
    grid-template-rows: 63%;
    justify-content: space-between;

    height: 100%;
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
    width: 100%;

    > div {
      &:nth-of-type(1) {
        grid-area: personal;
      }

      &:nth-of-type(2) {
        display: none;
        grid-area: tops;
      }

      &:nth-of-type(3) {
        grid-area: competition;
      }

      &:nth-of-type(4) {
        display: none;
        grid-area: sums;
      }
    }
  }
}
