@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.mobile {
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 3rem;
  z-index: 2;

  height: 1.599rem;
  width: 5.756rem;

  pointer-events: none;
  transform: rotateX(180deg);
  transition: transform 300ms linear;

  &.rotate {
    transform: rotateX(0deg);
  }
}

.laptop {
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 1.4rem;
  z-index: 2;

  height: 1.499rem;
  width: 4.556rem;

  pointer-events: none;
  transform: rotateX(180deg);
  transition: transform 300ms linear;

  &.rotate {
    transform: rotateX(0deg);
  }
}
