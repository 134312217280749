@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

$start: 1;
$end: 6;

.desktop {
  @include font-giorgio-sans-lcg;

  align-items: center;
  display: flex;
  justify-content: center;

  height: 2.625rem;
  width: 2.886rem;

  color: #eceaff;
  font-size: 2rem;
  line-height: 2.625rem;

  &.even {
    color: black;
  }

  @for $i from $start through $end {
    &.color-#{$i} {
      background: url(~/src/assets/icons/line-#{$i}.svg) no-repeat;
      background-size: 100% 100%;
      background-position: center;
    }
  }
}

.mobile {
  @include font-giorgio-sans-lcg;

  align-items: center;
  display: flex;
  justify-content: center;

  height: 8.178rem;
  width: 8.178rem;

  color: #eceaff;
  font-size: 6.074rem;
  line-height: 6.074rem;

  &.even {
    color: black;
  }

  @for $i from $start through $end {
    &.color-#{$i} {
      background: url(~/src/assets/icons/line-#{$i}.svg) no-repeat;
      background-size: 100% 100%;
      background-position: center;
    }
  }
}

.laptop {
  @include font-giorgio-sans-lcg;

  align-items: center;
  display: flex;
  justify-content: center;

  height: 7.595149040082195rem;
  width: 7.595149040082195rem;

  color: #eceaff;
  font-size: 6.074rem;
  line-height: 6.074rem;

  &.even {
    color: black;
  }

  @for $i from $start through $end {
    &.color-#{$i} {
      background: url(~/src/assets/icons/line-#{$i}.svg) no-repeat;
      background-size: 100% 100%;
      background-position: center;
    }
  }
}

.v3 {
  @include font-giorgio-sans-lcg;

  align-items: center;
  display: flex;
  justify-content: center;

  height: 2.625rem;
  width: 2.886rem;

  color: #eceaff;
  font-size: 2rem;
  line-height: 2.625rem;

  &.even {
    color: black;
  }

  @for $i from $start through $end {
    &.color-#{$i} {
      background: url(~/src/assets/icons/line-#{$i}.svg) no-repeat;
      background-size: 100% 100%;
      background-position: center;
    }
  }
}
