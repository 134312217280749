@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.market {
  @include col;

  height: 100%;
  width: 100%;

  .header {
    flex: 1 0 11.5%;

    display: grid;
    grid-template-areas: "empty lines";
    grid-template-columns: 1fr 45.65rem;
  }

  .empty {
    grid-area: "empty";
  }

  .body {
    flex: 1 0 88.5%;

    position: relative;

    display: grid;
    grid-column-gap: 0.85rem;
    grid-template-areas: "lines bets";
    grid-template-columns: 1fr 45.65rem;
  }

  .bets {
    display: grid;
    grid-area: bets;
    grid-column-gap: 0.1875rem;
    grid-row-gap: 0.09375rem;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);

    padding-bottom: 1rem;
  }

  .limit {
    bottom: 0rem;
    position: absolute;

    height: 1.1875rem;
    width: 45.65rem;

    transition: filter 300ms linear;

    &.filter {
      filter: brightness(0.65);
    }

    &:before {
      bottom: 0;
      left: 0;
      margin: auto;
      position: absolute;
      top: 0;

      height: 0.0625rem;
      width: 21rem;

      background: #435189;
      content: "";
    }

    &:after {
      bottom: 0;
      right: 0;
      margin: auto;
      position: absolute;
      top: 0;

      height: 0.0625rem;
      width: 21rem;

      background: #435189;
      content: "";
    }
  }

  .lines {
    grid-area: lines;

    z-index: 1;

    &:first-child {
      @include col;

      align-items: center;
      justify-content: space-around;

      padding-bottom: 1rem;
    }

    &:last-child {
      @include row;

      align-items: center;
      justify-content: space-around;
    }

    button {
      display: flex;
    }

    img {
      height: 2rem;
    }
  }

  .row-1,
  .row-2,
  .row-3,
  .row-4,
  .row-5,
  .row-6,
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6 {
    filter: brightness(0.65);

    &.light {
      filter: brightness(1);
    }
  }
}

.market.dark {
  .limit {
    bottom: 0.1rem;
    position: absolute;

    height: 1.1875rem;
    width: 45.65rem;

    background: url(~/src/widgets/markets/assets/images/v3/dark/limit-personal-bottom.png);
    background-size: 100% 100%;

    &:before {
      display: none;
    }

    &:after {
      display: none;
    }
  }
}

.market.markets {
  @include col;

  height: 100%;
  width: 100%;

  .header {
    flex: 1 0 11.5%;

    display: grid;
    grid-template-areas: "lines empty";
    grid-template-columns: 53.25rem 1fr;
  }

  .empty {
    grid-area: empty;
  }

  .body {
    flex: 1 0 88.5%;

    position: relative;

    display: grid;
    grid-column-gap: 1.55rem;
    grid-template-areas: "bets lines";
    grid-template-columns: 53.25rem 1fr;
    grid-template-rows: 99%;
  }

  .bets {
    display: grid;
    grid-area: bets;
    grid-column-gap: 0.1875rem;
    grid-row-gap: 0.09375rem;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);

    position: relative;

    padding-bottom: 1rem;

    &:before {
      position: absolute;

      height: 96.5%;
      width: 100%;

      background: linear-gradient(180deg, #121625 0%, #101421 100%);
      box-shadow: 0 0.0625rem 0 0 #0d1018 inset;
      border-radius: 0.5rem;
      content: "";
    }
  }

  .limit {
    bottom: 0rem;
    position: absolute;

    height: 1.1875rem;
    width: 53.25rem;

    &:before {
      bottom: 0;
      left: 0;
      margin: auto;
      position: absolute;
      top: 0;

      height: 0.0625rem;
      width: 24.5rem;

      background: #8c92d1;
      content: "";
    }

    &:after {
      bottom: 0;
      right: 0;
      margin: auto;
      position: absolute;
      top: 0;

      height: 0.0625rem;
      width: 24.5rem;

      background: #8c92d1;
      content: "";
    }
  }

  .lines {
    grid-area: lines;

    z-index: 1;

    &:first-child {
      @include col;

      align-items: center;
      justify-content: space-around;

      padding-bottom: 1rem;
    }

    &:last-child {
      @include row;

      align-items: center;
      justify-content: space-around;
    }

    button {
      display: flex;
    }

    img {
      height: 2rem;
    }
  }
}
