@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.button {
  bottom: 3.5rem;
  left: 2.8rem;
  position: absolute;
  z-index: 2;

  height: 4.015625rem;
  width: 4.415625rem;

  span {
    @include font-giorgio-sans-lcg;

    display: block;

    color: #a0a7b7;
    font-size: 2.75rem;
    transition: color 200ms linear, transform 200ms linear;
  }

  background: url(~/src/widgets/panel/assets/images/v3/common/tutorial-button-default.png)
    no-repeat;
  background-size: 100% 100%;

  &:hover {
    background: url(~/src/widgets/panel/assets/images/v3/common/tutorial-button-hover.png)
      no-repeat;
    background-size: 100% 100%;
  }
  &:active {
    background: url(~/src/widgets/panel/assets/images/v3/common/tutorial-button-active.png)
      no-repeat;
    background-size: 100% 100%;

    span {
      color: #7cfa4c;
      transform: translate3d(0, 0.1rem, 0);
    }
  }
}

.button.dark {
  bottom: 3.5rem;
  left: 2.8rem;
  position: absolute;
  z-index: 2;

  height: 4.015625rem;
  width: 4.415625rem;

  span {
    @include font-giorgio-sans-lcg;

    display: block;

    color: #a0a7b7;
    font-size: 2.75rem;
    transition: color 200ms linear, transform 200ms linear;
  }

  background: url(~/src/widgets/panel/assets/images/v3/dark/tutorial-button-default.png)
    no-repeat;
  background-size: 100% 100%;

  &:hover {
    background: url(~/src/widgets/panel/assets/images/v3/dark/tutorial-button-hover.png)
      no-repeat;
    background-size: 100% 100%;
  }
  &:active {
    background: url(~/src/widgets/panel/assets/images/v3/dark/tutorial-button-active.png)
      no-repeat;
    background-size: 100% 100%;

    span {
      color: #6f98ff;
      transform: translate3d(0, 0.1rem, 0);
    }
  }
}
