@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  @include row;

  overflow: hidden;
  position: relative;

  height: 3.5rem;

  .games {
    align-items: center;
    display: flex;
    justify-content: space-around;

    pointer-events: none;
    position: absolute;
    z-index: 1;

    height: 100%;
    width: 100%;
  }

  .game {
    @include button-main;
    @include font-giorgio-sans-lcg;

    align-items: center;
    display: flex;
    justify-content: center;

    height: 100%;
    width: 50%;

    font-size: 1.75rem;
    letter-spacing: 0.07rem;
    line-height: 3.063rem;
  }

  .button {
    flex: 0 0 13.438rem;

    position: absolute;

    height: 100%;
    width: 50%;

    background: url(~/src/widgets/coupons/assets/images/common/game-default.png);
    background-size: 100% 100%;
    transition: background 0.2s linear;

    &.selected {
      background: url(~/src/widgets/coupons/assets/images/common/game-selected.png) !important;
      background-size: 100% 100% !important;
    }

    &:hover {
      background: url(~/src/widgets/coupons/assets/images/common/game-hover.png);
      background-size: 100% 100%;
    }
    &:active {
      background: url(~/src/widgets/coupons/assets/images/common/game-active.png);
      background-size: 100% 100%;
    }
  }
}

.mobile {
  @include row;

  overflow: visible;
  position: relative;

  height: 16.346rem;

  touch-action: none;

  .games {
    align-items: center;
    display: flex;
    justify-content: space-around;

    position: absolute;
    z-index: 1;

    height: 100%;
    width: 100%;
  }

  .game {
    @include button-main;

    align-items: center;
    display: flex;
    gap: 2.941rem;
    justify-content: center;

    height: 100%;
    width: 50%;

    > span {
      &:first-child {
        @include font-giorgio-sans-lcg;

        font-size: 6.394rem;
      }

      &:last-child {
        @include row;
        @include font-lato;

        align-items: center;
        justify-content: center;

        height: 5.769rem;
        width: 8.655rem;

        background: #ebe9fe;
        border-radius: 8.655rem;
        color: #171d30;
        font-size: 4.326rem;
        font-weight: 800;
      }
    }

    &:active {
      > span {
        &:last-child {
          background: #7cfa4c;
        }
      }
    }

    &.selected {
      > span {
        &:last-child {
          background: #7cfa4c;
        }
      }
    }
  }

  .button {
    flex: 0 0 13.438rem;

    left: -8.5%;
    position: absolute;

    height: 100%;
    width: 58%;

    background: url(~/src/widgets/coupons/assets/images/mobile/game-default.png);
    background-size: 100% 100%;
    transition: background 0.2s linear;

    &.selected {
      background: url(~/src/widgets/coupons/assets/images/mobile/game-selected.png) !important;
      background-size: 100% 100% !important;
    }

    &:active {
      background: url(~/src/widgets/coupons/assets/images/mobile/game-selected.png);
      background-size: 100% 100%;
    }

    &:last-child {
      left: -10%;
    }
  }
}

.laptop {
  @include row;

  overflow: visible;
  position: relative;

  height: 11.129015rem;

  touch-action: none;

  .games {
    align-items: center;
    display: flex;
    justify-content: space-around;

    position: absolute;
    z-index: 1;

    height: 100%;
    width: 100%;
  }

  .game {
    @include button-main;

    align-items: center;
    display: flex;
    gap: 2.941rem;
    justify-content: center;

    height: 100%;
    width: 50%;

    > span {
      &:first-child {
        @include font-giorgio-sans-lcg;

        font-size: 4.501605rem;
        letter-spacing: 0.1rem;
      }

      &:last-child {
        @include row;
        @include font-lato;

        align-items: center;
        justify-content: center;

        height: 4.21935rem;
        width: 6.329025rem;

        background: #ebe9fe;
        border-radius: 6.3295rem;
        color: #171d30;
        font-size: 3.1645rem;
        font-weight: 800;
      }
    }

    &:active {
      > span {
        &:last-child {
          background: #7cfa4c;
        }
      }
    }

    &.selected {
      > span {
        &:last-child {
          background: #7cfa4c;
        }
      }
    }
  }

  .button {
    flex: 0 0 13.438rem;

    left: -8.5%;
    position: absolute;

    height: 100%;
    width: 58%;

    background: url(~/src/widgets/coupons/assets/images/mobile/game-default.png);
    background-size: 100% 100%;
    transition: background 0.2s linear;

    &.selected {
      background: url(~/src/widgets/coupons/assets/images/mobile/game-selected.png) !important;
      background-size: 100% 100% !important;
    }

    &:active {
      background: url(~/src/widgets/coupons/assets/images/mobile/game-selected.png);
      background-size: 100% 100%;
    }

    &:last-child {
      left: -10%;
    }
  }
}
