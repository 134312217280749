@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

$operations: double, repeat, undo;

.desktop {
  height: 4.938rem;
  width: 17.563rem;

  background: url(~/src/components/control/assets/images/desktop/default.png)
    no-repeat;
  background-size: 100% 100%;

  svg {
    display: none;
  }

  span {
    @include font-giorgio-sans-lcg;

    display: block;

    color: #a0a7b7;
    font-size: 2.375rem;
    letter-spacing: 0.1rem;
    transition: color 200ms linear, transform 200ms linear;
  }

  &:hover {
    background: url(~/src/components/control/assets/images/desktop/hover.png)
      no-repeat;
    background-size: 100% 100%;
  }
  &:active {
    background: url(~/src/components/control/assets/images/desktop/active.png)
      no-repeat;
    background-size: 100% 100%;

    span {
      color: #7cfa4c;
      transform: translate3d(0, 0.1rem, 0);
    }
  }
  &:disabled {
    background: url(~/src/components/control/assets/images/desktop/disabled.png)
      no-repeat;
    background-size: 100% 100%;

    span {
      color: #2f354d;
    }
  }
}

.mobile {
  display: flex;
  justify-content: center;

  height: 11.6225rem;
  width: 12.8603274rem;

  svg,
  span {
    display: none;
  }
}

.mobile.double {
  background: url(~/src/components/control/assets/images/mobile/button-double-mobile-default.png)
    no-repeat;
  background-size: 100% 100%;

  &:enabled:active {
    background: url(~/src/components/control/assets/images/mobile/button-double-mobile-active.png)
      no-repeat;
    background-size: 100% 100%;
  }
  &:disabled {
    background: url(~/src/components/control/assets/images/mobile/button-double-mobile-disabled.png)
      no-repeat;
    background-size: 100% 100%;
  }
}

.mobile.repeat {
  background: url(~/src/components/control/assets/images/mobile/button-repeat-mobile-default.png)
    no-repeat;
  background-size: 100% 100%;

  &:enabled:active {
    background: url(~/src/components/control/assets/images/mobile/button-repeat-mobile-active.png)
      no-repeat;
    background-size: 100% 100%;
  }
  &:disabled {
    background: url(~/src/components/control/assets/images/mobile/button-repeat-mobile-disabled.png)
      no-repeat;
    background-size: 100% 100%;
  }
}

.laptop {
  display: flex;
  justify-content: center;

  height: 10.575rem;
  width: 11.7035rem;

  svg,
  span {
    display: none;
  }
}

.laptop.double {
  background: url(~/src/components/control/assets/images/mobile/button-double-mobile-default.png)
    no-repeat;
  background-size: 100% 100%;

  &:enabled:active {
    background: url(~/src/components/control/assets/images/mobile/button-double-mobile-active.png)
      no-repeat;
    background-size: 100% 100%;
  }
  &:disabled {
    background: url(~/src/components/control/assets/images/mobile/button-double-mobile-disabled.png)
      no-repeat;
    background-size: 100% 100%;
  }
}

.laptop.repeat {
  background: url(~/src/components/control/assets/images/mobile/button-repeat-mobile-default.png)
    no-repeat;
  background-size: 100% 100%;

  &:enabled:active {
    background: url(~/src/components/control/assets/images/mobile/button-repeat-mobile-active.png)
      no-repeat;
    background-size: 100% 100%;
  }
  &:disabled {
    background: url(~/src/components/control/assets/images/mobile/button-repeat-mobile-disabled.png)
      no-repeat;
    background-size: 100% 100%;
  }
}

.v3 {
  align-items: center;
  display: flex;
  gap: 0.45875rem;
  justify-content: center;

  height: 100%;
  width: 100%;

  svg {
    fill: #a0a7b7;
    transition: fill 200ms linear, transform 200ms linear;
  }

  span {
    @include font-giorgio-sans-lcg;

    display: block;

    color: #a0a7b7;
    font-size: 1.875rem;
    transition: color 200ms linear, transform 200ms linear;
  }

  background: url(~/src/components/control/assets/images/v3/common/default.png)
    no-repeat;
  background-size: 100% 100%;

  &:enabled:hover {
    background: url(~/src/components/control/assets/images/v3/common/hover.png)
      no-repeat;
    background-size: 100% 100%;
  }
  &:enabled:active {
    background: url(~/src/components/control/assets/images/v3/common/active.png)
      no-repeat;
    background-size: 100% 100%;

    svg {
      fill: #7cfa4c;
      transform: translate3d(0, 0.1rem, 0);
    }

    span {
      color: #7cfa4c;
      transform: translate3d(0, 0.1rem, 0);
    }
  }
  &:disabled {
    background: url(~/src/components/control/assets/images/v3/common/disabled.png)
      no-repeat;
    background-size: 100% 100%;

    svg {
      fill: #2f354d;
    }

    span {
      color: #2f354d;
    }
  }
}

.v3.dark {
  svg {
    fill: #a0a7b7;
    transition: fill 200ms linear, transform 200ms linear;
  }

  span {
    @include font-giorgio-sans-lcg;

    display: block;

    color: #a0a7b7;
    font-size: 1.875rem;
    transition: color 200ms linear, transform 200ms linear;
  }

  background: url(~/src/components/control/assets/images/v3/dark/default.png)
    no-repeat;
  background-size: 100% 100%;

  &:enabled:hover {
    background: url(~/src/components/control/assets/images/v3/dark/hover.png)
      no-repeat;
    background-size: 100% 100%;
  }
  &:enabled:active {
    background: url(~/src/components/control/assets/images/v3/dark/active.png)
      no-repeat;
    background-size: 100% 100%;

    svg {
      fill: #6f98ff;
      transform: translate3d(0, 0.1rem, 0);
    }

    span {
      color: #6f98ff;
      transform: translate3d(0, 0.1rem, 0);
    }
  }
  &:disabled {
    background: url(~/src/components/control/assets/images/v3/dark/disabled.png)
      no-repeat;
    background-size: 100% 100%;

    svg {
      fill: #2f354d;
    }

    span {
      color: #2f354d;
    }
  }
}
