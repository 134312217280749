@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  bottom: 1rem;
  position: absolute;
  right: 9.875rem;
  z-index: 1;

  span {
    color: #030509;
    font-family: "Roboto Mono";
    font-size: 0.6875rem;
    font-weight: 400;
    letter-spacing: 0.05rem;
  }
}

.v3 {
  bottom: 0.8125rem;
  position: absolute;
  right: 1.375rem;
  z-index: 1;

  span {
    color: #1c2030;
    font-family: "Roboto Mono";
    font-size: 0.6875rem;
    font-weight: 400;
    letter-spacing: 0.05rem;
  }
}
