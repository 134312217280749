@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  @include row;

  align-items: center;

  height: 2.2rem;

  .label {
    @include font-lato;

    flex: 1 0 50%;

    color: #798bb5;
    font-size: 0.938rem;
    letter-spacing: 0.05rem;
    line-height: 1.219rem;
  }
}

.mobile {
  @include row;

  align-items: center;

  height: 9.591rem;

  .label {
    @include font-lato;

    flex: 1 0 50%;

    color: #798bb5;
    font-size: 4.156rem;
    letter-spacing: 0.05rem;
    line-height: 5.435rem;
  }
}

.laptop {
  @include row;

  align-items: flex-end;

  height: 6.5rem;
  padding: 0 12.58062rem;

  .label {
    @include font-lato;

    flex: 1 0 50%;

    color: #798bb5;
    font-size: 2.39835rem;
    letter-spacing: 0.05rem;
    line-height: 4.435rem;
  }
}

.v3 {
  @include row;

  align-items: center;
  gap: 0.34875rem;

  padding: 0.1rem 0.475rem;

  .count {
    @include row;
    @include font-lato;

    align-items: center;
    justify-content: center;

    height: 1.0625rem;
    width: 1.71125rem;

    background: #e5e2fd;
    border-radius: 1.758125rem;
    color: #171d30;
    font-size: 0.8125rem;
    font-weight: 800;
    letter-spacing: 0.05rem;
    line-height: 1.171875rem;
    transform: skewX(-5deg);

    &.active {
      background: #7cfa4c;
    }

    &.hidden {
      display: none;
    }
  }

  .text {
    @include font-lato;

    color: #e5e2fd;
    font-size: 0.8125rem;
    font-weight: 800;
    letter-spacing: 0.05rem;
    line-height: 1.171875rem;

    &.active {
      color: #7cfa4c;
    }
  }

  .win {
    @include font-lato;

    color: #e5e2fd;
    font-size: 0.8125rem;
    font-weight: 800;
    letter-spacing: 0.05rem;
    line-height: 1.171875rem;

    &.hidden {
      display: none;
    }
  }
}

.v3.dark {
  @include row;

  align-items: center;
  gap: 0.34875rem;

  padding: 0.1rem 0.475rem;

  .count {
    @include row;
    @include font-lato;

    align-items: center;
    justify-content: center;

    height: 1.0625rem;
    width: 1.71125rem;

    background: #e5e2fd;
    border-radius: 1.758125rem;
    color: #171d30;
    font-size: 0.8125rem;
    font-weight: 800;
    letter-spacing: 0.05rem;
    line-height: 1.171875rem;
    transform: skewX(-5deg);

    &.active {
      background: #89aaff;
    }

    &.hidden {
      display: none;
    }
  }

  .text {
    @include font-lato;

    color: #e5e2fd;
    font-size: 0.8125rem;
    font-weight: 800;
    letter-spacing: 0.05rem;
    line-height: 1.171875rem;

    &.active {
      color: #89aaff;
    }
  }

  .win {
    @include font-lato;

    color: #e5e2fd;
    font-size: 0.8125rem;
    font-weight: 800;
    letter-spacing: 0.05rem;
    line-height: 1.171875rem;

    &.hidden {
      display: none;
    }
  }
}
