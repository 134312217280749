@mixin font-giorgio-sans-lcg {
  font-family: Giorgio Sans LCG;
  font-style: italic;
  font-weight: 700;
}

@mixin font-lato {
  font-family: Lato;
  font-weight: 700;
}
