@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.desktop {
  @include row;

  gap: 0.063rem;

  height: 4.875rem;
  width: 27rem;

  background: #1a2035;
  border-bottom: 0.25rem solid #1a2035;

  .lines {
    @include row;

    align-items: center;
    flex: 1 0 auto;
    justify-content: space-around;
  }

  .line {
    @include font-giorgio-sans-lcg;

    font-size: 1.75rem;
    line-height: 1.596rem;

    &.odd {
      color: white;
    }
  }

  .rate {
    @include font-lato;
    @include row;

    align-items: center;
    flex: 1 0 auto;
    justify-content: center;

    color: #eceaff;
    font-size: 1.375rem;
    line-height: 2.625rem;
  }

  .title {
    @include font-lato;

    align-items: center;
    display: flex;
    padding: 0.938rem 0 0.938rem 0.5rem;

    background: #060917;
    color: #a0a7b7;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 0.1rem;
  }

  .data {
    display: grid;
    flex: 1;
    grid-row-gap: 0.063rem;
    grid-template-columns: 5.813rem 1fr;
    grid-template-rows: 1.938rem 1fr;

    width: 100%;

    &.sumtop2,
    &.sumtop3 {
      grid-column-gap: 0.063rem;
    }
  }

  .button {
    @include font-giorgio-sans-lcg;

    position: relative;

    height: 4.625rem;
    width: 6.875rem;

    background: transparent;
    color: #eceaff;
    font-size: 1.5rem;
    letter-spacing: 0.04em;
    line-height: 1.425rem;

    &:hover {
      background: #1f2849;
      color: #cfcafd;
    }
    &:focus {
      background: #37415c;
      color: #eceaff;
    }
    &:active {
      background: #1a2035;
      color: #cfcafd;
    }
  }

  .spinner {
    @include absolute-center;

    display: none;

    &.spin {
      animation: rotate 1000ms infinite linear;
      display: block;
    }
  }

  &.selected {
    border-bottom: 0.25rem solid #291a81;
  }

  &.pending,
  &.confirmed,
  &.loss {
    .title {
      background: #0b0c22;
    }
  }

  &.win {
    .title {
      background: #291a81;
      color: #eceaff;
    }
  }

  &.pending {
    .button {
      color: #2f354d;
      pointer-events: none;
    }
  }

  &.confirmed,
  &.loss {
    .button {
      background: #0b0c22;
      color: #2f354d;
      pointer-events: none;
    }
  }

  &.win {
    .button {
      background: #291a81;
      pointer-events: none;
    }
  }
}

.mobile {
  @include row;

  gap: 0.265rem;

  height: 20.608rem;
  width: 114.138rem;

  background: #1a2035;
  border-bottom: 1.058rem solid #1a2035;

  .lines {
    @include row;

    align-items: center;
    flex: 1 0 auto;
    justify-content: space-around;
  }

  .line {
    @include font-giorgio-sans-lcg;

    font-size: 7.398rem;
    line-height: 7.398rem;

    &.odd {
      color: white;
    }
  }

  .rate {
    @include font-lato;
    @include row;

    align-items: center;
    flex: 1 0 auto;
    justify-content: center;

    color: #eceaff;
    font-size: 5.754rem;
    line-height: 2.625rem;
  }

  .title {
    @include font-lato;

    align-items: center;
    display: flex;
    padding: 0.938rem 0 0.938rem 0.5rem;

    background: #060917;
    color: #a0a7b7;
    font-size: 3.516rem;
    font-weight: 600;
    line-height: 0.1rem;
  }

  .data {
    display: grid;
    flex: 1;
    grid-row-gap: 0.265rem;
    grid-template-columns: 24.57rem 1fr;
    grid-template-rows: 8.191rem 1fr;

    width: 100%;
  }

  .button {
    @include font-giorgio-sans-lcg;

    position: relative;

    height: 19.75rem;
    width: 29.062rem;

    background: transparent;
    color: #eceaff;
    font-size: 6.394rem;
    letter-spacing: 0.04em;

    &:hover {
      background: #1f2849;
      color: #cfcafd;
    }
    &:focus {
      background: #37415c;
      color: #eceaff;
    }
    &:active {
      background: #1a2035;
      color: #cfcafd;
    }
  }

  .spinner {
    @include absolute-center;

    display: none;

    &.spin {
      animation: rotate 1000ms infinite linear;
      display: block;
    }
  }

  &.selected {
    border-bottom: 1.058rem solid #291a81;
  }

  &.pending,
  &.confirmed,
  &.loss {
    .title {
      background: #0b0c22;
    }
  }

  &.win {
    .title {
      background: #291a81;
      color: #eceaff;
    }
  }

  &.pending {
    .button {
      color: #2f354d;
      pointer-events: none;
    }
  }

  &.confirmed,
  &.loss {
    .button {
      background: #0b0c22;
      color: #2f354d;
      pointer-events: none;
    }
  }

  &.win {
    .button {
      background: #291a81;
      pointer-events: none;
    }
  }
}

.laptop {
  @include row;

  gap: 0.265rem;

  height: 11.775rem;
  width: 94.99985rem;

  background: #1a2035;
  border-bottom: 0.51935rem solid #1a2035;

  .lines {
    @include row;

    align-items: center;
    flex: 1 0 auto;
    justify-content: space-around;
  }

  .line {
    @include font-giorgio-sans-lcg;

    font-size: 4.2775rem;
    line-height: 4.2775rem;

    &.odd {
      color: white;
    }
  }

  .rate {
    @include font-lato;
    @include row;

    align-items: center;
    flex: 1 0 auto;
    justify-content: center;

    color: #eceaff;
    font-size: 3.32095rem;
    line-height: 2.625rem;
  }

  .title {
    @include font-lato;

    align-items: center;
    display: flex;
    padding: 1.21935rem 0 1.21935rem 1.21935rem;

    background: #060917;
    color: #a0a7b7;
    font-size: 2.02905rem;
    font-weight: 600;
    line-height: 0.1rem;
  }

  .data {
    display: grid;
    flex: 1;
    grid-row-gap: 0.265rem;
    grid-template-columns: 14.20805rem 1fr;
    grid-template-rows: 4.735rem 1fr;

    width: 100%;
  }

  .button {
    @include font-giorgio-sans-lcg;

    position: relative;

    height: 11.25rem;
    width: 16.790295rem;

    background: transparent;
    color: #eceaff;
    font-size: 3.690315rem;
    letter-spacing: 0.04em;

    &:hover {
      background: #1f2849;
      color: #cfcafd;
    }
    &:focus {
      background: #37415c;
      color: #eceaff;
    }
    &:active {
      background: #1a2035;
      color: #cfcafd;
    }
  }

  .spinner {
    @include absolute-center;

    display: none;

    &.spin {
      animation: rotate 1000ms infinite linear;
      display: block;
    }
  }

  &.selected {
    border-bottom: 0.51935rem solid #291a81;
  }

  &.pending,
  &.confirmed,
  &.loss {
    .title {
      background: #0b0c22;
    }
  }

  &.win {
    .title {
      background: #291a81;
      color: #eceaff;
    }
  }

  &.pending {
    .button {
      color: #2f354d;
      pointer-events: none;
    }
  }

  &.confirmed,
  &.loss {
    .button {
      background: #0b0c22;
      color: #2f354d;
      pointer-events: none;
    }
  }

  &.win {
    .button {
      background: #291a81;
      pointer-events: none;
    }
  }
}
