@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.panel {
  @include row;

  bottom: -0.6rem;
  position: absolute;
  right: 0;
  z-index: 1;

  height: 19.375rem;
  width: 90rem;

  background: url(~/src/widgets/panel/assets/images/desktop/panel.png) no-repeat;
  background-size: 100% 100%;

  .board {
    @include row;

    position: relative;

    height: 19.375rem;
    width: 58.188rem;
  }
}
