.desktop {
  &.competition {
    height: 0rem;
    width: 0rem;
  }

  &.cup1,
  &.cup2,
  &.cup3,
  &.cup4,
  &.cup5,
  &.cup6 {
    height: 2.25rem;
    width: 2.571rem;
  }

  &.cups2 {
    height: 2.25rem;
    width: 4.515rem;
  }

  &.cups2empty {
    height: 0rem;
    width: 0rem;
  }

  &.cups3 {
    height: 2.25rem;
    width: 6.405rem;
  }

  &.cups3empty {
    height: 0rem;
    width: 0rem;
  }

  &.sum12 {
    height: 2.25rem;
    width: 5.438rem;
  }

  &.sum12empty {
    height: 0rem;
    width: 0rem;
  }

  &.sum123 {
    height: 2.25rem;
    width: 8.25rem;
  }

  &.sum123empty {
    height: 0rem;
    width: 0rem;
  }
}

.mobile {
  &.competition {
    height: 9.287rem;
    width: 12.793rem;
  }

  &.cup1,
  &.cup2,
  &.cup3,
  &.cup4,
  &.cup5,
  &.cup6 {
    height: 7.353rem;
    width: 8.696rem;
  }

  &.cups2 {
    height: 7.353rem;
    width: 14.465rem;
  }

  &.cups2empty {
    height: 7.353rem;
    width: 14.59rem;
  }

  &.cups3 {
    height: 7.353rem;
    width: 20.469rem;
  }

  &.cups3empty {
    height: 7.353rem;
    width: 17.814rem;
  }

  &.sum12 {
    height: 7.353rem;
    width: 18.713rem;
  }

  &.sum12empty {
    height: 7.353rem;
    width: 13.493rem;
  }

  &.sum123 {
    height: 7.353rem;
    width: 27.54rem;
  }

  &.sum123empty {
    height: 7.353rem;
    width: 17.814rem;
  }
}
