@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.desktop {
  @include absolute-center;
  @include col;

  gap: 2.625rem;
  justify-content: flex-end;

  height: 41.625rem;
  width: 38.5rem;

  .confetti {
    inset: 0;
    position: fixed;
    z-index: -1;

    height: 100%;
    margin: auto;
    width: 70rem;
  }

  .shine {
    bottom: 17%;
    left: -51rem;
    position: absolute;
    top: 0;
    z-index: -1;

    height: 78.75rem;
    margin: auto;
    width: 140rem;

    animation: fade 1s ease-out 0s 1;
  }
}

.mobile,
.laptop {
  @include absolute-center;
  @include col;

  align-items: center;
  gap: 4%;
  justify-content: center;

  height: 155.639rem;
  width: 110.98rem;

  background-size: contain;

  .confetti {
    inset: 0;
    position: fixed;
    z-index: -1;

    height: 100%;
    margin: auto;
    width: 100%;
  }

  .shine {
    bottom: 80rem;
    left: -140rem;
    position: absolute;
    top: 0;
    z-index: -1;

    height: 216.204rem;
    margin: auto;
    width: 384.434rem;

    animation: fade 1s ease-out 0s 1;
  }
}

.v3 {
  @include absolute-center;
  @include col;

  gap: 2.625rem;
  justify-content: flex-end;

  height: 47.625rem;
  width: 56.6875rem;

  .confetti {
    inset: 0;
    position: fixed;
    z-index: -1;

    height: 100%;
    margin: auto;
    width: 70rem;
  }

  .shine {
    bottom: 17%;
    left: -42rem;
    position: absolute;
    top: 0;
    z-index: -1;

    height: 78.75rem;
    margin: auto;
    width: 140rem;

    animation: fade 1s ease-out 0s 1;
  }
}
