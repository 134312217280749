@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.market {
  @include col;

  height: 100%;
  width: 100%;

  .header {
    flex: 1 0 20%;

    display: grid;
    grid-template-columns: 7.5rem 102rem 7.5rem;
  }

  .body {
    flex: 1 0 80%;

    position: relative;

    display: grid;
    grid-template-columns: 7.5rem 102rem 7.5rem;
  }

  .cups {
    @include col;

    z-index: 1;

    align-items: flex-start;
    justify-content: space-around;

    padding-bottom: 1rem;

    &:last-child {
      align-items: flex-end;
    }

    button {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    img {
      height: 2rem;
    }
  }

  .racers {
    @include row;

    align-items: center;
    justify-content: space-around;

    z-index: 1;

    span {
      @include font-giorgio-sans-lcg;

      width: 100%;

      color: #eceaff;
      font-size: 1.375rem;
      text-align: center;
    }
  }

  @for $i from 3 through 15 {
    .sum-#{$i} {
      filter: brightness(0.65);

      &.light {
        filter: brightness(1);
      }
    }
  }

  .bets {
    @include col;

    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 0.1875rem;
    grid-row-gap: 0.09375rem;
    grid-template-columns: repeat(13, 1fr);
    grid-template-rows: repeat(2, 1fr);

    padding-bottom: 1rem;

    > div {
      &:nth-of-type(2) {
        background: url(~/src/widgets/markets/assets/images/v3/default/cell-left-bottom.png)
          no-repeat;
        background-size: 100% 100%;
      }

      &:nth-of-type(25) {
        background: url(~/src/widgets/markets/assets/images/v3/default/cell-right-top.png)
          no-repeat;
        background-size: 100% 100%;
      }

      &:nth-of-type(4),
      &:nth-of-type(6),
      &:nth-of-type(19),
      &:nth-of-type(21),
      &:nth-of-type(23) {
        background: url(~/src/widgets/markets/assets/images/v3/default/cell-center-center.png)
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .limit {
    bottom: 0rem;
    position: absolute;

    height: 1.1875rem;
    width: 102rem;

    transition: filter 300ms linear;

    &.filter {
      filter: brightness(0.65);
    }

    &:before {
      bottom: 0;
      left: 0;
      margin: auto;
      position: absolute;
      top: 0;

      height: 0.0625rem;
      width: 49rem;

      background: #435189;
      content: "";
    }

    &:after {
      bottom: 0;
      right: 0;
      margin: auto;
      position: absolute;
      top: 0;

      height: 0.0625rem;
      width: 49rem;

      background: #435189;
      content: "";
    }
  }

  .sumtop2,
  .sumtop3 {
    filter: brightness(0.65);

    &.light {
      filter: brightness(1);
    }
  }
}

.market.dark {
  .bets {
    @include col;

    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 0.1875rem;
    grid-row-gap: 0.09375rem;
    grid-template-columns: repeat(13, 1fr);
    grid-template-rows: repeat(2, 1fr);

    padding-bottom: 1rem;

    > div {
      &:nth-of-type(2) {
        background: url(~/src/widgets/markets/assets/images/v3/dark/cell-left-bottom.png)
          no-repeat;
        background-size: 100% 100%;
      }

      &:nth-of-type(25) {
        background: url(~/src/widgets/markets/assets/images/v3/dark/cell-right-top.png)
          no-repeat;
        background-size: 100% 100%;
      }

      &:nth-of-type(4),
      &:nth-of-type(6),
      &:nth-of-type(19),
      &:nth-of-type(21),
      &:nth-of-type(23) {
        background: url(~/src/widgets/markets/assets/images/v3/dark/cell-center-center.png)
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .limit {
    bottom: 0.1rem;
    position: absolute;

    height: 1.1875rem;
    width: 102rem;

    background: url(~/src/widgets/markets/assets/images/v3/dark/limit-sumtop-bottom.png);
    background-size: 100% 100%;

    &:before {
      display: none;
    }

    &:after {
      display: none;
    }
  }
}
