@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop,
.mobile,
.laptop {
  display: none;
}

.v3 {
  @include col;
  @include font-giorgio-sans-lcg;

  padding-left: 1rem;

  > span {
    &:first-child {
      font-size: 2.085rem;
      letter-spacing: 0.06rem;
      line-height: 1.8rem;
    }

    &:last-child {
      color: #9289ee;
      font-size: 1.25rem;
      letter-spacing: 0.01rem;
    }
  }
}
