@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.tab {
  @include col;

  left: -0.937rem;
  position: absolute;

  height: 8.375rem;
  padding-left: 3rem;
  padding-right: 2rem;
  padding-top: 0.6875rem;
  width: 41.687rem;

  background: url(~/src/widgets/info/assets/images/v3/common/next-default.png)
    no-repeat;
  background-size: 100% 100%;
  clip-path: polygon(2% 0%, 100% 0%, 98% 100%, 0% 100%);
  transition: background 100ms ease-in;

  &:first-child {
    background: url(~/src/widgets/info/assets/images/v3/common/game-default.png)
      no-repeat;
    background-size: 100% 100%;

    &.selected {
      background: url(~/src/widgets/info/assets/images/v3/common/game-selected.png)
        no-repeat;
      background-size: 100% 100%;
    }

    @media (hover: hover) {
      &:hover {
        background: url(~/src/widgets/info/assets/images/v3/common/game-selected.png)
          no-repeat;
        background-size: 100% 100%;
      }
    }

    &:active {
      background: url(~/src/widgets/info/assets/images/v3/common/game-selected.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }

  &:last-child {
    pointer-events: none;
  }

  &.selected {
    background: url(~/src/widgets/info/assets/images/v3/common/next-selected.png)
      no-repeat;
    background-size: 100% 100%;
  }

  @media (hover: hover) {
    &:hover {
      background: url(~/src/widgets/info/assets/images/v3/common/next-selected.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }

  &:active {
    background: url(~/src/widgets/info/assets/images/v3/common/next-selected.png)
      no-repeat;
    background-size: 100% 100%;
  }

  .header {
    height: 1.3375rem;
    width: 100%;
  }

  .body {
    @include row;

    align-items: flex-end;
    justify-content: space-between;
  }

  .filter {
    filter: brightness(0.5);
    transition: filter 100ms ease-in;

    &.selected {
      filter: brightness(1);
    }
  }
}

.tab.dark {
  @include col;

  left: -0.937rem;
  position: absolute;

  height: 8.375rem;
  padding-left: 3rem;
  padding-right: 2rem;
  padding-top: 0.6875rem;
  width: 41.687rem;

  background: url(~/src/widgets/info/assets/images/v3/dark/next-default.png)
    no-repeat;
  background-size: 100% 100%;
  clip-path: polygon(2% 0%, 100% 0%, 98% 100%, 0% 100%);
  transition: background 100ms ease-in;

  &:first-child {
    background: url(~/src/widgets/info/assets/images/v3/dark/game-default.png)
      no-repeat;
    background-size: 100% 100%;

    &.selected {
      background: url(~/src/widgets/info/assets/images/v3/dark/game-selected.png)
        no-repeat;
      background-size: 100% 100%;
    }

    @media (hover: hover) {
      &:hover {
        background: url(~/src/widgets/info/assets/images/v3/dark/game-selected.png)
          no-repeat;
        background-size: 100% 100%;
      }
    }

    &:active {
      background: url(~/src/widgets/info/assets/images/v3/dark/game-selected.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }

  &:last-child {
    pointer-events: none;
  }

  &.selected {
    background: url(~/src/widgets/info/assets/images/v3/dark/next-selected.png)
      no-repeat;
    background-size: 100% 100%;
  }

  @media (hover: hover) {
    &:hover {
      background: url(~/src/widgets/info/assets/images/v3/dark/next-selected.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }

  &:active {
    background: url(~/src/widgets/info/assets/images/v3/dark/next-selected.png)
      no-repeat;
    background-size: 100% 100%;
  }

  .header {
    height: 1.3375rem;
    width: 100%;
  }

  .body {
    @include row;

    align-items: flex-end;
    justify-content: space-between;
  }

  .filter {
    filter: brightness(0.5);
    transition: filter 100ms ease-in;

    &.selected {
      filter: brightness(1);
    }
  }
}

.tab.markets {
  @include col;

  left: -0.937rem;
  position: absolute;

  height: 8.375rem;
  padding-left: 3rem;
  padding-right: 2rem;
  padding-top: 0.9875rem;
  width: 41.687rem;

  background: url(~/src/widgets/info/assets/images/v3/common/next-default.png)
    no-repeat;
  background-size: 100% 100%;
  clip-path: polygon(2% 0%, 100% 0%, 98% 100%, 0% 100%);
  transition: background 100ms ease-in;

  &:first-child {
    background: url(~/src/widgets/info/assets/images/v3/common/game-default.png)
      no-repeat;
    background-size: 100% 100%;

    &.selected {
      background: url(~/src/widgets/info/assets/images/v3/common/game-selected.png)
        no-repeat;
      background-size: 100% 100%;
    }

    @media (hover: hover) {
      &:hover {
        background: url(~/src/widgets/info/assets/images/v3/common/game-selected.png)
          no-repeat;
        background-size: 100% 100%;
      }
    }

    &:active {
      background: url(~/src/widgets/info/assets/images/v3/common/game-selected.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }

  &:last-child {
    pointer-events: none;
  }

  &.selected {
    background: url(~/src/widgets/info/assets/images/v3/common/next-selected.png)
      no-repeat;
    background-size: 100% 100%;
  }

  @media (hover: hover) {
    &:hover {
      background: url(~/src/widgets/info/assets/images/v3/common/next-selected.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }

  &:active {
    background: url(~/src/widgets/info/assets/images/v3/common/next-selected.png)
      no-repeat;
    background-size: 100% 100%;
  }

  .header {
    height: 1.3375rem;
    width: 100%;
  }

  .body {
    @include row;

    align-items: flex-end;
    justify-content: space-between;
  }

  .filter {
    filter: brightness(0.5);
    transition: filter 100ms ease-in;

    &.selected {
      filter: brightness(1);
    }
  }
}
