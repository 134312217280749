@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.market {
  @include col;

  align-items: center;

  position: absolute;
  top: calc(0.174 * (100vh - 17.083rem));

  height: calc(0.7768 * (100vh - 17.083rem));
  width: 45.5rem;

  .header {
    @include row;

    align-items: center;
    gap: 18.8rem;
    justify-content: flex-start;

    padding-left: 7.5rem;

    width: 100%;

    img {
      &:first-child {
        width: 5.4rem;
      }

      &:last-child {
        width: 8.4rem;
      }
    }

    &:before {
      @include market-title;

      content: "СУММА НОМЕРОВ ПОБЕДИТЕЛЕЙ";

      left: 12.8rem;
      position: absolute;

      width: 20rem;
    }

    span {
      @include market-title;
    }

    span {
      width: 20rem;
    }
  }

  .body {
    @include row;

    gap: 5.875rem;

    position: absolute;
    top: calc(0.07 * (100vh - 17.083rem));
  }

  .bets {
    display: grid;
    grid-column-gap: 0.25rem;
    grid-template-columns: repeat(3, 1fr);

    width: 19.8125rem;

    &:first-child {
      grid-auto-rows: 33.5%;

      height: calc(0.4945 * (100vh - 17.083rem));
    }

    &:last-child {
      grid-auto-rows: 25.175%;

      height: calc(0.65796 * (100vh - 17.083rem));
    }
  }

  .bet {
    @include col;

    &:nth-of-type(n + 4) {
      justify-content: center;
    }

    &:nth-of-type(n + 7) {
      justify-content: flex-end;
    }

    &:nth-of-type(n + 10) {
      grid-column: 2/3;
    }
  }

  .racers {
    @include font-giorgio-sans-lcg;

    text-align: center;

    color: #eceaff;
    font-size: 1.6875rem;
  }
}
