@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  @include col;

  flex: 1;
  overflow: hidden;

  .wrapper {
    position: relative;

    height: 100%;
  }
}

.mobile,
.laptop {
  @include col;

  flex: 1;
  overflow: hidden;

  .wrapper {
    flex: 1 0 auto;

    position: relative;
  }
}
