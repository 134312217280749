@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.speedometer {
  display: flex;
  justify-content: flex-end;

  position: relative;

  height: 19.375rem;
  width: 31.813rem;

  .svg {
    position: absolute;
    right: 0.5%;
    top: 0.2%;

    height: 88.05%;
    width: 91.365%;
  }

  .rect {
    offset-path: path(
      "M486 45.5C342.036 45.5 258.588 45.5 130.052 45.5C96.3581 45.5 68.2379 71.2464 65.2887 104.811L49.5 284.5"
    );
  }
}
