@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  @include row;

  height: 100%;
  width: 200%;

  .view {
    position: absolute;

    height: 100%;
    width: 50%;
  }
}

.mobile {
  @include row;

  height: 100%;
  width: 200%;

  .view {
    position: absolute;

    height: 100%;
    width: 50%;
  }
}

.laptop {
  @include row;

  height: 100%;
  width: 200%;

  .view {
    position: absolute;

    height: 100%;
    padding: 0 12.5805rem;
    width: 50%;
  }
}
