@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.tabs {
  @include row;

  position: relative;
  z-index: 0;

  height: 8.375rem;
  width: 100%;
}
