@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop,
.mobile,
.laptop {
  display: none;
}

.v3 {
  span {
    @include font-giorgio-sans-lcg;

    color: #ffffff;
    font-size: 4.5rem;
    line-height: 4rem;
  }
}
