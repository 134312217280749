@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.cars {
  @include col;

  align-items: center;
  justify-content: space-between;

  position: relative;

  padding: 0 2rem;
  padding-bottom: 7.5%;

  background: url(~/src/assets/images/desktop/race-1.png) no-repeat,
    url(~/src/assets/images/desktop/race-2.png) no-repeat;
  background-size: contain, 100% 100%;

  .header {
    @include font-giorgio-sans-lcg;

    color: #eceaff;
    font-size: 1.75rem;
    letter-spacing: 0.015rem;
    line-height: 3.1rem;
  }

  .body {
    @include col;

    position: relative;

    height: 86.5%;
    width: 100%;
  }

  .car {
    position: absolute;

    height: calc(4.188rem + calc((100% - (4.188rem * 6)) / 5));
    width: 100%;
  }
}
