@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.mobile {
  @include col;

  flex: 1 0 auto;

  padding: 0 4.476rem;
  padding-bottom: 50rem;
  width: 100%;

  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  .body {
    @include col;
  }

  .data {
    display: grid;
    grid-column-gap: 2.878rem;
    grid-template-columns: 9.275rem 1fr;
  }

  .cups {
    @include row;

    align-items: center;
    justify-content: space-around;

    position: sticky;
    top: 0;
    z-index: 1;

    height: 14.788rem;

    background: rgba(21, 26, 43, 1);
  }

  .lines {
    @include col;

    justify-content: space-around;
  }

  .bets {
    display: grid;
    grid-column-gap: 0.95948725rem;
    grid-row-gap: 0.479743625rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, 15.351rem);

    &.tops {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.laptop {
  @include col;

  flex: 1 0 auto;

  padding: 0 15.5rem;
  padding-bottom: 50rem;
  width: 100%;

  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  .body {
    @include col;

    gap: 5.25rem;
  }

  .data {
    display: grid;
    grid-column-gap: 2.878rem;
    grid-template-columns: 7.275rem 1fr;

    &:last-child {
      .cups {
        img {
          width: 10rem;

          &:last-child {
            width: 15rem;
          }
        }
      }
    }
  }

  .cups {
    @include row;

    align-items: center;
    justify-content: space-around;

    position: sticky;
    top: 0;
    z-index: 1;

    height: 7.788rem;

    background: rgba(21, 26, 43, 1);

    img {
      width: 6.575rem;
    }
  }

  .lines {
    @include col;

    justify-content: space-around;

    div {
      width: 6.5rem;

      font-size: 4.275rem;
    }
  }

  .bets {
    display: grid;
    grid-column-gap: 0.6775rem;
    grid-row-gap: 0.3225rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, 11.2905rem);

    &.tops {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
