@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/sizes.scss";
@import "src/shared/styles/typography.scss";

.desktop {
  @include col;

  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;

  height: calc(100vh - 25rem);

  &::-webkit-scrollbar {
    display: none;
  }

  .wrapper {
    position: absolute;

    height: 5.25rem;
    width: 27rem;
  }
}

.mobile {
  @include col;

  align-items: center;

  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;

  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  .wrapper {
    position: absolute;

    height: 22.194rem;
    width: 114.138rem;
  }
}

.laptop {
  @include col;

  align-items: center;

  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;

  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  .wrapper {
    position: absolute;

    height: 12.6885rem;
    width: 94.99985rem;
  }
}
